import React, { useEffect, useState } from 'react';
import { Link } from "@react-email/components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faYoutube, faInstagram, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import baseURL from '../Environment';
export default function Footer() {
  // Define state for form inputs
  const [form, setForm] = useState({
    fullname: '',
    email: '',
    message: '',
  });

  // Define the form submission function
  const formsubmit = (e) => {
    e.preventDefault();
    const request = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(form)
    };
    // Send a POST request to the server with the form data
    fetch(baseURL + `contact`, request)
      .then((response) => response.json())
      .then((result) => {
        // Reset the form fields after successful submission
        setForm({
          fullname: '',
          email: '',
          message: '',
        });
      });
  };

  // Add WhatsApp widget
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://d2mpatx37cqexb.cloudfront.net/delightchat-whatsapp-widget/embeds/embed.min.js';
    script.onload = () => {
      const wa_btnSetting = {
        "btnColor": "#16BE45",
        "ctaText": "WhatsApp Us",
        "cornerRadius": 40,
        "marginBottom": 20,
        "marginLeft": 20,
        "marginRight": 20,
        "btnPosition": "right",
        "whatsAppNumber": "7942546624",
        "welcomeMessage": "Welcome to Livon Comforts",
        "zIndex": 999999,
        "btnColorScheme": "light"
      };
      window._waEmbed(wa_btnSetting);
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Define a function to collect form input values
  const collectinput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  // Define a function to handle phone link click
  const handlePhoneLinkClick = () => {
    const phoneNumber = "+919458419999";
    window.open(`tel:${phoneNumber}`, "_self");
  };
  return (
    <div className='Footer'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6'>
            <div className="location">
              <h3>Location</h3>
              <p className='mt-2'><Link href="https://maps.app.goo.gl/H9QP1vJKzqvtDt1F7" target='blank' className='link'>SY NO.148 TSIIC, Kallakal, Telangana 502336.</Link></p>
              <p className='mt-2'><Link href="mailto:sales@livoncomforts.com" className='link'>sales@livoncomforts.com</Link></p>
              <p className='mt-2'><Link href="#" className='link' onClick={handlePhoneLinkClick}>Phone: (+91) 79 4254 6624</Link></p>
            </div>
            <div className='SocialMedia-icons'>
              <h3 className='mt-4'>Follow us</h3>
              <div className='row text-start'>
                <div className='text-start col-lg-1 col-1'>
                  <FontAwesomeIcon icon={faInstagram} className="insta_icons" />
                </div>
                <div className='col-lg-1 col-1'>
                  <FontAwesomeIcon icon={faXTwitter} className="twitter_icon" />
                </div>
                <div className='col-lg-1 col-1'>
                  <FontAwesomeIcon icon={faFacebook} className="facebook_icon" />
                </div>
                <div className='col-lg-1 col-1'>
                  <FontAwesomeIcon icon={faYoutube} className="youtube_icon" />
                </div>
              </div>
            </div>
            <p className='copy'>&copy; 2023 privacy policy</p>
          </div>
          <div className='col-lg-6 footer-form'>
            <h3 className='contact'>contact us</h3>
            <form onSubmit={formsubmit}>
              <input name='fullname' value={form.fullname} type="text" placeholder='Enter your name' required onChange={collectinput} /><br />
              <input name='email' value={form.email} type="email" placeholder='Enter a valid email address' required onChange={collectinput} /><br />
              <textarea name='message' value={form.message} placeholder='Enter your message' rows={4} required onChange={collectinput}></textarea><br />
              <input className='footer-form_submit' type='submit' />
            </form>
          </div>
        </div>
      </div>
      <div className='mt-5 poweredBy'>
        <div className='container down-footer'>
          <span className='company-name'>Livon Comforts</span>
          <span className='poweredBy-nova'>Proudly Powered By <a href="https://novatales.com/" target="_blank" rel="noreferrer"> Novatales</a></span>
        </div>
      </div>
    </div>
  );
}