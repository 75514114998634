import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Header.css';
import Quizgirl from './../images/shutterstock_133755326 (1).jpg';
export default function Quiz() {
  return (
    <div className='quiz_content'>
        <div className="quiz">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="quiz-image">
          <img src={Quizgirl} alt="image" className='image'/>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
           <div className="quiz-content">
            <h2>Find your <span>ideal mattress</span></h2>
            <h3>Take a quiz to <br/>
              see which mattress suits you best!</h3>           
              <button className='quiz-content_button'><Link to="/mattress-selector">Take a quiz</Link></button>
           </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
