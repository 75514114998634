import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import './Manageaddress.css';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Row } from 'react-bootstrap';
import FormControl from '@mui/material/FormControl';
import useUserId from './UseUserId'; // Import the custom hook
import baseURL from '../Environment';
const ManageaddresPage = () => {
  // State variables for managing display and data
  const [isDisplayed, setIsDisplayed] = useState(false); // Controls the display of the address form
  const [data, setData] = useState(null); // Stores the fetched address data
  // State variable for managing form data
  const [form, setForm] = useState({
    fullname: '',
    address: '',
    city: '',
    state: '',
    pincode: '',
    email: '',
    phoneNumber: '',
  });

  // The user ID obtained from the useUserId() hook.
  const userId = useUserId();

  /**
   * Toggles the display state of an element.
   */
  const toggleDisplay = () => {
    setIsDisplayed(!isDisplayed);
  };

  // Function to fetch address data from the API
  const getapi = () => {
    if (userId) {
      fetch(baseURL + `users/${userId}/all-addresses`)
        .then((Response) => {
          if (!Response.ok) {
            throw new Error('Failed to fetch addresses');
          }
          return Response.json();
        })
        .then((result) => setData(result))
        .catch((error) => console.error('Error fetching addresses:', error));
    }
  };

  // Call the getapi function when the component mounts or when the userId changes
  useEffect(() => {
    getapi();
  }, [userId]);
  
  // Function to handle form submission
  const formsubmit = (e) => {
    e.preventDefault();
    const request = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(form)
    };

    fetch(baseURL + `users/${userId}/addresses`, request)
      .then((response) => response.json())
      .then((result) => {
        alert('Your Address Added !');
        setForm({
          fullname: '',
          address: '',
          city: '',
          state: '',
          pincode: '',
          email: '',
          phoneNumber: '',
        });
        getapi();
      })
  }


  // Function to collect input values from form fields
  const collectinput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  // Check if data is null
  if (data == null) {
    return (
      <p> Loading</p>
    )
  }


  return (
    <>
      <div>
        <div className='mt-4 mb-5 manage_address'>
          <h2 className='personal-info'>Manage Addresses</h2>
        </div>
        <Form className='manage_address'>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <FormControl className='manageaddrerss_cls' as="select" style={{ width: '50%', background: '#edf5f4', padding: '20px 20px 20px 4px', marginLeft: '-9px' }}>
              {data.addresses && data.addresses.length > 0 ? (
                data.addresses.map((addressItem, index) => (
                  <option key={index} value={index} className='col-lg-6 card'>
                    {addressItem.deliveryAddress && (
                      <>
                        {addressItem.deliveryAddress.fullname}, {addressItem.deliveryAddress.email}, {' '}
                        {addressItem.deliveryAddress.address}, {addressItem.deliveryAddress.city}, {' '}
                        {addressItem.deliveryAddress.state} - {addressItem.deliveryAddress.pincode} {' '}
                        {addressItem.deliveryAddress.phoneNumber}
                      </>
                    )}
                  </option>
                ))
              ) : (
                <option>No addresses available</option>
              )}
            </FormControl>

          </Form.Group>
        </Form>

        <div className='add-address'>
          <div className='post-address text-center'>
            <p className='display-1 plus mx-3 my-2' onClick={toggleDisplay}>  <FontAwesomeIcon icon={faPlus} /> </p>
            <p>Add a new address</p>
            <div className='new-address' id="referral" style={{ display: isDisplayed ? 'block' : 'none' }}>
                  <Form className='my-3' style={{ width: '95%' }} onSubmit={formsubmit}>
                    <Row>
                      <Form.Group as={Col} md="4" controlId="validationCustom01" className='w-50 my-2'>
                        <Form.Control
                          type="text"
                          placeholder="Full Name"
                          name="fullname"
                          value={form.fullname}
                          onChange={collectinput}
                          required
                        />
                      </Form.Group>

                      <Form.Group as={Col} md="4" controlId="validationCustom02" className='w-50 my-2'>
                        <Form.Control
                          type="text" ZD
                          placeholder="Email"
                          name="email"
                          value={form.email}
                          onChange={collectinput}
                          required
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="4" controlId="validationCustom02" className='w-100 my-2'>
                        <Form.Control
                          type="text"
                          placeholder="Phone Number"
                          name="phoneNumber"
                          value={form.phoneNumber}
                          onChange={collectinput}
                          required
                        />
                      </Form.Group>

                      <Form.Group as={Col} md="4" controlId="validationCustom01" className='full-address w-100'>
                        <textarea
                          className='field-location textarea-addres w-100 p-2' rows='5'
                          type="text"
                          placeholder="Full Address"
                          name="address"
                          value={form.address}
                          onChange={collectinput}
                          required
                        />
                      </Form.Group>

                      <Form.Group as={Col} md="4" controlId="validationCustom02" className='w-100'>
                        <Form.Control
                          type="text"
                          placeholder="Enter City"
                          name="city"
                          value={form.city}
                          onChange={collectinput}
                          required
                        />
                      </Form.Group>

                      <Form.Group as={Col} md="4" controlId="validationCustom01" className='w-50 my-2'>
                        <Form.Select
                          type="text"
                          name="state"
                          value={form.state}
                          onChange={collectinput}
                          required
                          aria-label="Default select example">
                          <option value="SelectState">Select State</option>
                          <option value="Andra Pradesh">Andra Pradesh</option>
                          <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                          <option value="Assam">Assam</option>
                          <option value="Bihar">Bihar</option>
                          <option value="Chhattisgarh">Chhattisgarh</option>
                          <option value="Goa">Goa</option>
                          <option value="Gujarat">Gujarat</option>
                          <option value="Haryana">Haryana</option>
                          <option value="Himachal Pradesh">Himachal Pradesh</option>
                          <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                          <option value="Jharkhand">Jharkhand</option>
                          <option value="Karnataka">Karnataka</option>
                          <option value="Kerala">Kerala</option>
                          <option value="Madya Pradesh">Madya Pradesh</option>
                          <option value="Maharashtra">Maharashtra</option>
                          <option value="Manipur">Manipur</option>
                          <option value="Meghalaya">Meghalaya</option>
                          <option value="Mizoram">Mizoram</option>
                          <option value="Nagaland">Nagaland</option>
                          <option value="Orissa">Orissa</option>
                          <option value="Punjab">Punjab</option>
                          <option value="Rajasthan">Rajasthan</option>
                          <option value="Sikkim">Sikkim</option>
                          <option value="Tamil Nadu">Tamil Nadu</option>
                          <option value="Telangana">Telangana</option>
                          <option value="Tripura">Tripura</option>
                          <option value="Uttaranchal">Uttaranchal</option>
                          <option value="Uttar Pradesh">Uttar Pradesh</option>
                          <option value="West Bengal">West Bengal</option>
                          <option disabled style={{ background: '#aaa', color: '#fff' }}>UNION Territories</option>
                          <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                          <option value="Chandigarh">Chandigarh</option>
                          <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
                          <option value="Daman and Diu">Daman and Diu</option>
                          <option value="Delhi">Delhi</option>
                          <option value="Lakshadeep">Lakshadeep</option>
                          <option value="Pondicherry">Pondicherry</option>
                        </Form.Select>
                      </Form.Group>
                      <Form.Group as={Col} md="4" controlId="validationCustom01" className='w-50 my-2'>
                        <Form.Control
                          type="text"
                          placeholder="Pin code"
                          name="pincode"
                          value={form.pincode}
                          onChange={collectinput}
                          required
                        />
                      </Form.Group>
                    </Row>

                    <div className="text-center my-2">
                      <button className="btn btn-outline-success p-2 ms-2" >Add Address</button>
                    </div>

                  </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageaddresPage;