import React, {useEffect} from 'react'
import './../App.css';
import './Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import HeroImage from './HeroImage';
import Categories from './Categories';
import Benefits from './Benefits';
import Certifications from './Certifications';
import SavePlanet from './SavePlanet';
import Quiz from './Quiz';
import MattressProductList from './MattressProductList';
import PillowProducts from './PillowProducts';
import TopperProducts from './TopperProducts';
import KidsProducts from './KidsProducts';
import { useLocation } from 'react-router-dom';
export default function Home() {
  const location = useLocation();

  // Scroll to element based on location hash
  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  return (
    
    <div>
          
            <HeroImage />
            <Categories />
            <Benefits />
            <MattressProductList id='Mattress-Products'/>
            <PillowProducts id='pillow-products'/>
            <TopperProducts id='topper-products'/>
            <KidsProducts id='kids-products'/>
            <Certifications />
            <SavePlanet />
            <Quiz />
             
    </div>
  )
}
