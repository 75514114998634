import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import { faChevronRight, faQuestion } from '@fortawesome/free-solid-svg-icons';
import trial from './../images/trial.png';
import warranty from './../images/warranty.png';
import delivery from './../images/free-delivery.png';
import Rating from "react-rating";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Addtocart from "./Addtocart";
import Specifications from "./Specifications";
import Faqs from "./Faqs";
import baseURL from "../Environment";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useUserId from './UseUserId'; // Import the custom hook
import Reviews from "./Reviews";



export default function SingleProductDetails() {
  // State variables
  const [product, setProduct] = useState(null); // Holds the product data
  const [count, setCount] = useState(1); // Holds the quantity of the product
  const [sideBarshow, setSidebarShow] = useState(false); // Controls the visibility of the sidebar
  const [open, setOpen] = React.useState(false); // Controls the visibility of a dialog
  const [open1, setOpen1] = React.useState(false); // Controls the visibility of another dialog
  const [activeImg, setActiveImage] = useState(product?.imageurl[0]); // Holds the active image
  const [selectedCoverDescriptions, setSelectedCoverDescriptions] = useState([]); // Holds the descriptions of the selected cover
  const [emiData, setEmiData] = useState(null); // Holds the EMI data
  const [selectedBank, setSelectedBank] = useState("Citibank"); // Holds the selected bank for EMI
  // Selection state
  const [selectedHeight, setSelectedHeight] = useState(''); // Holds the selected height
  const [selectedCategoryType, setSelectedCategoryType] = useState(null); // Holds the selected category type
  const [selectedDimension, setSelectedDimension] = useState(null); // Holds the selected dimension
  const [selectedPrice, setSelectedPrice] = useState(null); // Holds the selected price
  const [selectedPackOf, setSelectedPackOf] = useState(null); // Holds the selected pack of items
  const [selectedCover, setSelectedCover] = useState(''); // Holds the selected cover


  // Initial form values for the contact form
  const initialFormValues = {
    fullname: '',
    email: '',
    contactNumber: '',
    whatsappNumber: '',
    message: ''
  };

  // State variables for form values and errors
  const [formValues, setFormValues] = useState(initialFormValues); // Holds the values of the contact form
  const [errors, setErrors] = useState({}); // Holds the validation errors for the contact form

  // Function to handle form input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
    // Clear error message when user starts typing again
    setErrors({
      ...errors,
      [name]: ''
    });
  };

  // Function to handle numeric input and allow only numbers
  const handleNumericInput = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9]+$/;
    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  // Function to handle phone number input change
  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;
    // Clear error message when user starts typing again
    setErrors({
      ...errors,
      contactNumber: ''
    });
    if (value.length <= 10) {
      setFormValues({
        ...formValues,
        contactNumber: value
      });
    }
  };

  // Function to handle whatsapp number input change
  const handleWhatsappNumberChange = (event) => {
    const { value } = event.target;
    // Clear error message when user starts typing again
    setErrors({
      ...errors,
      whatsappNumber: ''
    });
    if (value.length <= 10) {
      setFormValues({
        ...formValues,
        whatsappNumber: value
      });
    }
  };

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    const formJson = {
      ...formValues,
      label: product.title
    };
    const formErrors = {};
    let hasErrors = false;

    // Validate each field
    Object.keys(formValues).forEach(key => {
      if (!formValues[key]) {
        formErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
        hasErrors = true;
      }
    });

    // If there are errors, set them and prevent form submission
    if (hasErrors) {
      setErrors(formErrors);
      return;
    }

    // Send form data to the server
    fetch(baseURL + 'customroute', {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formJson)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(data => {
        // Handle successful form submission
        setFormValues(initialFormValues); // Reset form values
        handleCloseDialog();
      })
      .catch(error => {
        console.error("Error:", error);
      });
  };

  // Function to reset form values when the dialog is closed
  const handleCloseDialog = () => {
    setFormValues(initialFormValues);
    handleClose();
    setErrors(false);
  };

  // Function to handle closing the dialog
  const handleCloseDialog1 = () => {
    handleClose();
  };

  // Handle cover selection
  const handleCoverSelection = (coverText) => {
    setSelectedCover(coverText);

    const selectedCoverData = Object.values(product.covers).find(coverList => coverList.some(cover => cover.name === coverText));

    if (selectedCoverData && selectedCoverData.length > 0) {
      const selectedCoverImage = selectedCoverData[0].image;
      setActiveImage(selectedCoverImage);
      // Update descriptions
      const selectedCoverDescriptions = selectedCoverData[0].descriptions || [];
      setSelectedCoverDescriptions(selectedCoverDescriptions);
    }
  };

  // Default to the first cover
  useEffect(() => {
    if (product && product.covers) {
      const firstCover = Object.entries(product.covers)[0];
      setSelectedCover(firstCover[1][0].name);
      // Update descriptions directly without calling handleCoverSelection
      const selectedCoverData = firstCover[1][0];
      const selectedCoverDescriptions = selectedCoverData.descriptions || [];
      setSelectedCoverDescriptions(selectedCoverDescriptions);
    }
  }, [product]);



  // Function to handle image click and set the active image
  const handleImageClick = (index) => {
    setActiveImage(index);
  };

  // Extracting category, id, and title from the URL parameters
  const { category, id, title } = useParams();

  // Function to handle opening the dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Function to handle opening another dialog
  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  // Function to handle closing the dialog
  const handleClose = () => {
    setOpen(false);
    setOpen1(false);
  };


  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Function to handle incrementing the count
  const handleAdd = () => {
    if (count < 10) {
      setCount(count + 1);
    }
  };

  // Function to handle subtracting from the count
  const handleSubtract = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };


  // Calculates the total amount based on the selected price and count.
  const totalAmount = (selectedPrice && ` ${selectedPrice}` * count);

// Function to fetch product details from the server
const fetchInfo = useCallback(async () => {
  try {
    const response = await fetch(`${baseURL}productdetails/${category}/${id}`);
    const data = await response.json();
    // Set the fetched product data
    setProduct(data);
    setActiveImage(data.imageurl[0]);
    setSelectedHeight(data.variants[0].attributes.find(attr => attr.name === 'height')?.value);
    setSelectedCategoryType(data.variants[0].attributes.find(attr => attr.name === 'categorytypes')?.value);
    setSelectedDimension(data.variants[0].attributes.find(attr => attr.name === 'dimension_inches')?.value);
    setSelectedPrice(data.variants[0].price);
    if (category === 'pillow' || title === 'Kids-Pillow') {
      setSelectedDimension(data.variants[0].attributes.find(attr => attr.name === 'dimension_cm')?.value);
      setSelectedPackOf(data.variants[0].attributes.find(attr => attr.name === 'pack_of')?.value);
    }
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
}, [category, id, title]);

// Fetch product details when the component mounts or when the category, id, or title changes
useEffect(() => {
  fetchInfo();
}, [fetchInfo]);


  // Function to handle height change
  const handleHeightChange = (height) => {
    setSelectedHeight(height);
    const selectedVariant = product.variants.find(variant => variant.attributes.find(attr => attr.name === 'height')?.value === height && variant.attributes.find(attr => attr.name === 'categorytypes')?.value === selectedCategoryType);
    if (selectedVariant) {
      setSelectedDimension(selectedVariant.attributes.find(attr => attr.name === 'dimension_inches')?.value);
      setSelectedPrice(selectedVariant.price);
    }
  };

  // Function to handle category type change
  const handleCategoryTypeChange = (categoryType) => {
    setSelectedCategoryType(categoryType);
    const selectedVariant = product.variants.find(variant => variant.attributes.find(attr => attr.name === 'height')?.value === selectedHeight && variant.attributes.find(attr => attr.name === 'categorytypes')?.value === categoryType);
    if (selectedVariant) {
      setSelectedDimension(selectedVariant.attributes.find(attr => attr.name === 'dimension_inches')?.value);
      setSelectedPrice(selectedVariant.price);
    }
    if (category === 'pillow' || title === 'Kids-Pillow') {
      if (selectedVariant) {
        setSelectedDimension(selectedVariant.attributes.find(attr => attr.name === 'dimension_cm')?.value);
        setSelectedPrice(selectedVariant.price);
      }
    }
  };

  // Function to handle dimension change
  const handleDimensionChange = (dimension) => {
    setSelectedDimension(dimension);
    const selectedVariant = product.variants.find(variant => variant.attributes.find(attr => attr.name === 'height')?.value === selectedHeight && variant.attributes.find(attr => attr.name === 'categorytypes')?.value === selectedCategoryType && variant.attributes.find(attr => attr.name === 'dimension_inches')?.value === dimension);
    if (selectedVariant) {
      setSelectedPrice(selectedVariant.price);
    }
  };

  // Function to handle pack of change
  const handlePackOfChange = (packOf) => {
    setSelectedPackOf(packOf);
    const selectedVariant = product.variants.find(variant =>
      variant.attributes.find(attr => attr.name === 'height')?.value === selectedHeight &&
      variant.attributes.find(attr => attr.name === 'categorytypes')?.value === selectedCategoryType &&
      variant.attributes.find(attr => attr.name === 'pack_of')?.value === packOf
    );
    if (selectedVariant) {
      setSelectedPrice(selectedVariant.price);
    }
  };



  // Get the user ID using the custom hook
  const userId = useUserId();


  // Function to handle adding the product to the cart
  const handleAddToCart = () => {
    // Create payload object with necessary data
    const payload = {
      userId: userId,
      id: id,
      category: product.category,
      title: product.title,
      imageurl: product.imageurl,
      quantity: count,
      originalPrice: selectedPrice * count,
      selectedHeight: selectedHeight,
      selectedCategoryType: selectedCategoryType,
      selectedDimension: selectedDimension,
      selectedCover: selectedCover,
      selectedPackOf: selectedPackOf,
      offers: product.offers
    };

    // Check if user is logged in
    if (userId) {
      // Send POST request to add product to cart
      fetch(baseURL + `cart/addtocart/${userId}/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        .then(response => response.json())
        .then(data => {
          // Set sidebar show to true to display the cart
          setSidebarShow(true);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    else {
      // If user is not logged in, store cart data in local storage
      let cartItems = JSON.parse(localStorage.getItem(id)) || [];
      if (!Array.isArray(cartItems)) {
        cartItems = [];
      }
      // Check if the same product with the same attributes already exists in the cart
      const existingIndex = cartItems.findIndex((item, index) => (
        item.id === payload.id &&
        (Array.isArray(item.selectedCategoryType) ? item.selectedCategoryType.every(t => payload.selectedCategoryType.includes(t)) : item.selectedCategoryType === payload.selectedCategoryType) &&
        (typeof item.selectedDimension === 'string' ? item.selectedDimension === payload.selectedDimension :
          (item.selectedDimension && item.selectedDimension[payload.selectedCategoryType]) ?
            item.selectedDimension[payload.selectedCategoryType].map(dim => dim.toString()).join(', ') === payload.selectedDimension.join(', ') :
            false) &&
        (Array.isArray(item.selectedHeight) ? item.selectedHeight.every(h => payload.selectedHeight.includes(h)) : item.selectedHeight === payload.selectedHeight) &&
        ((item.selectedCover && typeof item.selectedCover === 'object' && item.selectedCover !== null) ?
          Object.values(item.selectedCover).every(c => payload.selectedCover.includes(c)) :
          item.selectedCover === payload.selectedCover) &&
        (Array.isArray(item.selectedPackOf) ? item.selectedPackOf.every(p => payload.selectedPackOf.includes(p)) : item.selectedPackOf === payload.selectedPackOf)
      ));

      // If the same product exists, update the quantity
      if (existingIndex !== -1) {
        cartItems[existingIndex].quantity += payload.quantity;
        localStorage.setItem(id, JSON.stringify(cartItems));
      } else {
        // If the product does not exist, add it to the cart
        cartItems.push(payload);
        localStorage.setItem(id, JSON.stringify(cartItems));
      }

      // Set sidebar show to true to display the cart
      setSidebarShow(true);
    }
  };

  // Function to handle closing the side navigation
  const handleCloseSideNav = (data) => {
    setSidebarShow(data);
  };



  // Fetch EMI data when the component mounts
  useEffect(() => {
    fetchEmiData();
  }, []);

  // Function to fetch EMI data from the server
  const fetchEmiData = () => {
    fetch('https://livon-latex-backend-ylnnixg3qq-as.a.run.app/emi')
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          setEmiData(data[0].emiDetails);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  };


  // Check if product is not available yet
  if (!product) {
    return <div>Loading...</div>;
  }

  // Custom arrow component for the previous button
  const SamplePrevArrow = ({ className, style, onClick }) => {
    const handleClick = () => {
      onClick();
    };

    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: '#255852',
          borderRadius: '40px',
          border: 'none',
          boxShadow: 'none',
        }}
        onClick={handleClick}
      >
        Previous
      </div>
    );
  };

  // Custom arrow component for the next button
  const SampleNextArrow = ({ className, style, onClick }) => {
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: '#255852',
          borderRadius: '40px',
          border: 'none',
          boxShadow: 'none',
          color: 'red',
        }}
        onClick={onClick}
      >
        Next
      </div>
    );
  };

  // Slider settings for displaying product images
  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToScroll: 1,
    className: "center",
    centerPadding: "60px",
    slidesToShow: 3,
    swipeToSlide: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Check if the product has more than 2 images
  if (product.imageurl.length > 2) {
    // Set custom arrow components for previous and next buttons
    settings.prevArrow = <SamplePrevArrow />;
    settings.nextArrow = <SampleNextArrow />;
  }
  // Function to extract the percentage value from the offer text
  const extractPercentageValue = () => {
    const offerText = product.offers.text;
    const match = offerText.match(/\b(\d+)%/); // Match percentage value in the offer text
    if (match) {
      return parseInt(match[1]); // Extract the percentage value
    } else {
      return 0; // Return 0 if no percentage value found
    }
  };

  // Function to calculate the discount percentage
  const calculateDiscountPercentage = () => {
    const percentageValue = extractPercentageValue();
    return percentageValue;
  };

  // Function to calculate the discounted amount
  const calculateDiscountedAmount = () => {
    const discountPercentage = calculateDiscountPercentage();
    return (totalAmount * (discountPercentage / 100)).toFixed(2);
  };

  // Function to calculate the final price after discount
  const calculateFinalPrice = () => {
    const discountedAmount = calculateDiscountedAmount();
    return (totalAmount - discountedAmount).toFixed(2);
  };

  // Function to calculate EMI
  const calculateEMI = (principal, interest, months) => {
    if (interest === 0) {
      return (principal / months).toFixed(2);
    } else {
      const monthlyInterestRate = interest / (12 * 100);
      const emi = principal * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, months) / (Math.pow(1 + monthlyInterestRate, months) - 1);
      return emi.toFixed(2);
    }
  };

  // Find RBL Bank details
  const rblBankDetails = emiData?.find(bank => bank.bankName === "RBL Bank");

  // Find details for 24-month tenure
  const rbl24MonthsDetail = rblBankDetails?.details?.find(detail => detail.months === 24);

  // Calculate monthly EMI for 24 months for RBL Bank
  const rbl24MonthsEMI = rbl24MonthsDetail && calculateEMI(totalAmount, rbl24MonthsDetail.interest, rbl24MonthsDetail.months);

  // Function to handle bank selection
  const handleBankSelect = (bankName) => {
    setSelectedBank(bankName);
  };



  return (
    <div className="space">
      {product && (
        <div className="Mattress-product">
          <div className="container-fluid content_orders">
            <p className="para"><Link to="/">{product.category}</Link> <FontAwesomeIcon icon={faChevronRight} className="nav-product icon" /> {product.title}</p>
            <div className="row ">
              <div className="col-lg-6 first">
                <div className="flex Flex-col justify-between tg:flex-row">
                  <div className="flex flex-col gap-6">
                    <div id="imageMagnifyer" style={{ position: 'relative', zIndex: 1 }}>
                    <img src={activeImg} alt="active product" width="100%" />
                    </div>
                  </div>
                  <div className="slider-container">
                    <div className="px-10">
                      <Slider {...settings} >
                        {product.imageurl.map((imgurl, index) => (
                          <img
                            key={index}
                            src={imgurl}
                            onMouseEnter={() => handleImageClick(imgurl)}
                            alt={`product thumbnail ${index + 1}`}
                            className="image-Slider additional-images mt-4"
                            style={{ width: '100px', height: '100px', cursor: 'pointer' }}
                          />
                        ))}

                      </Slider >
                    </div>
                  </div>
                </div>
                <div className="content-des mt-5 description_1">
                  <h4 className="mb-3 single_description">Description for {product.title}</h4>
                  {product.descriptions && product.descriptions.length > 0 ? (
                    <ul>
                      {product.descriptions.map((description, index) => (
                        <li key={index}>{description}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>No description available.</p>
                  )}
                  {product && product.category === 'mattress' && product.descriptions ? (
                    <div>
                      <h4 className="mb-3 single_description">Description for {selectedCover}</h4>
                      <ul>
                        {selectedCoverDescriptions.length > 0 ? (
                          selectedCoverDescriptions.map((description, index) => (
                            <li key={index}>{description}</li>
                          ))
                        ) : (
                          <li>No cover description available.</li>
                        )}
                      </ul>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-6 ps-lg-5  row-rev">
                <div className="Mattress-content">
                  <h3>Livon Comforts</h3>
                  <h2 className='product-title'>{product?.title}</h2>
                  {/* <h3>average{product.averageRating}</h3> */}
                  <div className="star-rating mb-2">
                    <Rating
                      initialRating={product?.averageRating}
                      readonly
                      emptySymbol={<FontAwesomeIcon icon={regularStar} />}
                      fullSymbol={<FontAwesomeIcon icon={solidStar} />}
                    /> <span className="number-rating">{product?.averageRating}</span>
                  </div>
                  {/* {category !== 'pillow' && title !== 'Kids-Pillow' && (
                    <div className="size">
                      <p>Sizes</p>
                    </div>
                  )} */}
                  {category !== 'pillow' && title !== 'Kids-Pillow' && (
                    <div className="row select ">
                      <p className="col-lg-3 chart">Select a height </p>
                      <div className="col-lg-9 chart-size">
                        {[...new Set(product.variants.map(variant => variant.attributes.find(attr => attr.name === 'height')?.value))]
                          .sort((a, b) => parseFloat(a) - parseFloat(b))
                          .map(height => (
                            <button className="chart_heights"
                              key={height}
                              onClick={() => handleHeightChange(height)}
                              style={{
                                backgroundColor: selectedHeight === height ? 'rgb(163 204 196)' : '#edf5f4',
                                color: selectedHeight === height ? 'black' : 'black',
                              }}
                            >
                              {height} inches
                            </button>
                          ))}
                      </div>
                    </div>
                  )}
                  {category !== 'pillow' && title !== 'Kids-Pillow' && (
                    <div className="row variety mt-3">
                      <p className="col-lg-3 mb-4 mb-md-0 chart">Select Size </p>
                      <div className="col-lg-9 variety-sizes">
                        {[...new Set(product.variants.map(variant => variant.attributes.find(attr => attr.name === 'categorytypes')?.value))].map(categoryType => (
                          <button
                            key={categoryType} onClick={() => handleCategoryTypeChange(categoryType)}
                            style={{
                              backgroundColor: selectedCategoryType === categoryType ? 'black' : 'white',
                              color: selectedCategoryType === categoryType ? 'white' : 'black',
                            }}
                          >
                            {categoryType}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                  {((category === 'pillow' && product.variants[0].attributes.find(attr => attr.name === 'pack_of')) || title === 'Kids-Pillow') && (
                    <div className="row pack mt-3">
                      <p className="col-lg-3 chart ">Select a pack</p>
                      <div className="col-lg-9 pack-size">
                        {product.variants.map(variant => {
                          const packOfAttr = variant.attributes.find(attr => attr.name === 'pack_of');
                          if (packOfAttr) {
                            return (
                              <button key={packOfAttr.value} onClick={() => handlePackOfChange(packOfAttr.value)} className={selectedPackOf === packOfAttr.value ? 'active' : ''}
                              >Pillow {packOfAttr.value}</button>
                            );
                          }
                          return null;
                        })}
                      </div>
                    </div>
                  )}

                  {(category !== 'pillow' && title !== 'Kids-Pillow') && (
                    <div className="row mt-3 size-dimensions">
                      <p className="col-lg-3 chart">Select a Dimensions</p>
                      <div className="col-lg-9  mattresTypes">
                        <div className="row mattress_rows">
                          {product.variants.map(variant => (
                            selectedHeight === variant.attributes.find(attr => attr.name === 'height')?.value &&
                            selectedCategoryType === variant.attributes.find(attr => attr.name === 'categorytypes')?.value &&
                            variant.attributes.map(attr => (
                              attr.name === 'dimension_inches' &&
                              <div className="col-lg-4 col-4 mt-2 flexible-content" key={attr.value}>
                                <input
                                  type="radio"
                                  id={`radio_${attr.value}`}
                                  className="mattress_radiobtn"
                                  value={attr.value}
                                  checked={selectedDimension === attr.value}
                                  onChange={() => handleDimensionChange(attr.value)}
                                />
                                <label className="mattress_label" htmlFor={`radio_${attr.value}`}>
                                  <b className="dimen-inch">{attr.value}</b><br />
                                  {variant.attributes.find(attr => attr.name === 'dimension_inches').value === attr.value && (
                                    <span className="incm">({variant.attributes.find(attr => attr.name === 'dimension_cm').value} cm) </span>
                                  )}
                                </label>
                              </div>

                            ))
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  {(category === 'pillow' || title === 'Kids-Pillow') && (
                    <div className="row pillow-dimen mt-3">
                      <p className="col-lg-3 chart">Select a Dimensions</p>
                      <div className="col-lg-9 mattresTypes">
                        <div className="chart-size">
                          {[...new Set(product.variants.map(variant => variant.attributes.find(attr => attr.name === 'dimension_cm')?.value))].map(dimension => (
                            <label key={dimension}>
                              <input type="radio" value={dimension} checked={selectedDimension === dimension} onChange={() => setSelectedDimension(dimension)} />&nbsp;
                              {dimension}
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="dialog-box">
                    <p className='custom custom-button my-2'>
                      <span className="mb-0.5 text-xs font-semibold font-montserrat mainColour cursor-pointer" onClick={handleClickOpen}>CUSTOM SIZE</span>
                    </p>
                    <Dialog
                      open={open}
                      onClose={handleCloseDialog}
                    >
                      <form >
                        <h2 className="dialog-title">Custom Size for {product.title}</h2>
                        <DialogContent className="dialog-content">
                          <div className="row">
                            <div className="col-lg-4 mt-3">
                              <label>Name: </label>
                            </div>
                            <div className="col-lg-8">
                              <input type="text" name="fullname" value={formValues.fullname} onChange={handleChange} required />
                              {errors.fullname && <span className="error">{errors.fullname}</span>}
                            </div>
                            <div className="col-lg-4  mt-3">
                              <label>Email: </label>
                            </div>
                            <div className="col-lg-8">
                              <input type="email" name="email" value={formValues.email} onChange={handleChange} required />
                              {errors.email && <span className="error">{errors.email}</span>}
                            </div>
                            <div className="col-lg-4 mt-3 ">
                              <label>Contact Number: </label>
                            </div>
                            <div className="col-lg-8">
                              <input type="text" name="contactNumber" value={formValues.contactNumber} onChange={handlePhoneNumberChange} onKeyPress={handleNumericInput} maxLength="10" required />
                              {errors.contactNumber && <span className="error">{errors.contactNumber}</span>}
                            </div>
                            <div className="col-lg-4 mt-3 ">
                              <label>Whatsapp Number: </label>
                            </div>
                            <div className="col-lg-8">
                              <input type="text" name="whatsappNumber" value={formValues.whatsappNumber} onChange={handleWhatsappNumberChange} onKeyPress={handleNumericInput} maxLength="10" required />
                              {errors.whatsappNumber && <span className="error">{errors.whatsappNumber}</span>}
                            </div>
                            <div className="col-lg-4  mt-3">
                              <label>Your Message: </label>
                            </div>
                            <div className="col-lg-8">
                              <textarea name="message" value={formValues.message} onChange={handleChange} required></textarea>
                              {errors.message && <span className="error">{errors.message}</span>}
                            </div>
                          </div>
                          <DialogActions>
                            <Button onClick={handleCloseDialog} className="cancel">
                              Cancel
                            </Button>
                            <Button type="button" className="save" onClick={handleSubmit}>
                              Submit
                            </Button>
                          </DialogActions>
                        </DialogContent>
                      </form>
                    </Dialog>

                  </div>
                  <div className=" payment mt-2">
                    <div className="row">
                      <p className="col-lg-3 chart">Quantity</p>
                      <div className="col-lg-9 product-inc">
                        <button onClick={handleSubtract}>-</button>
                        <p>{count}</p>
                        <button onClick={handleAdd}>+</button>
                      </div>
                    </div>
                    <div className="row price-cls mt-3">
                      <p className="col-lg-3 col-12 sub_price chart">Price</p>
                      <div className="col-lg-9 col-12 row">
                        <div className="col-lg-5 col-5">
                          <p className="payonce">pay once</p>
                          <div className="price-details ">
                            <h4 className="sub_ammount"><span className="original-price">₹{totalAmount.toFixed(2)}</span></h4>
                            <span>{calculateFinalPrice()}/-</span>
                          </div>
                        </div>
                        {category !== 'pillow' && title !== 'Kids-Pillow' && (
                        <div className="col-lg-3 col-3 OR">
                          <p className="vert-line"></p>
                          <p>OR</p>
                          <p className="vert-line"></p>
                        </div>
                        )}
                         {category !== 'pillow' && title !== 'Kids-Pillow' && (
                        <div className="col-lg-4 col-4">
                          <p className="text-center payonce">Pay as low as</p>
                          <div className="price-details ">
                            <h4 className="sub_ammount"><span>₹{rbl24MonthsEMI}/mo</span></h4>
                            <div className="questionmark" onClick={handleClickOpen1}><FontAwesomeIcon icon={faQuestion} /></div>
                          </div>
                        </div>
                        )}
                         {category !== 'pillow' && title !== 'Kids-Pillow' && (
                        <Dialog
                          open={open1}
                          onClose={handleCloseDialog1}
                        >
                          <form >
                            <h2 className="dialog-title emi-title">EMI Options</h2>
                            <IconButton
                              aria-label="close"
                              onClick={handleClose}
                              sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                            <DialogContent className="dialog-content">
                              <div className="row">
                                <div className="col-lg-4 col-md-4 col-12 banks">
                                  {emiData?.map(bank => (
                                    <div
                                      key={bank._id}
                                      onClick={() => handleBankSelect(bank.bankName)}
                                      className={`bank-name ${selectedBank === bank.bankName ? 'active' : ''}`}
                                    >
                                      {bank.bankName}
                                    </div>
                                  ))}
                                </div>
                                <div className="col-lg-8 col-md-8 col-12 banks-table">
                                  <h3>{selectedBank} EMI PLANS</h3>
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th>Months</th>
                                        <th>Monthly EMI</th>
                                        <th>Overall Cost</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {selectedBank && emiData?.find(bank => bank.bankName === selectedBank)?.details?.map(detail => (
                                        <tr key={detail.months}>
                                          <td>{detail.months}</td>
                                          <td>{calculateEMI(totalAmount, detail.interest, detail.months)} ({detail.interest} %)</td>
                                          <td>{(detail.months * calculateEMI(totalAmount, detail.interest, detail.months)).toFixed(2)}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </DialogContent>
                          </form>
                        </Dialog>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row bedding-covers mt-3">
                    {product && product.category === 'mattress' && product.covers ? (
                      <h5 className="col-lg-3 chart">Select a Cover</h5>
                    ) : null}
                    <div className="col-lg-9 col-12">
                      <div className="row selected-cover">
                        {product && product.covers && Object.entries(product.covers).map(([key, coverArray], index) => (
                          <div
                            key={key}
                            className={`col-lg-6 select-covers ${selectedCover === coverArray[0].name || (parseInt(key) === 1 && selectedCover === '') ? 'active' : ''}`}
                            onClick={() => handleCoverSelection(coverArray[0].name)} // Pass the cover name here
                          >
                            <span className="cover-name">{coverArray[0].name}</span>
                            <p className="cover-para">({coverArray[0].notes})</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-9 col-md-12 col-12 add-to-cart">
                      <button onClick={handleAddToCart} type="button">ADD TO CART</button>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-9 ">
                      {product.offers && Object.keys(product.offers).length > 0 ? (
                        <div className="boxes">
                          <p className="offer-text">{product.offers.text}</p>
                        </div>
                      ) : (
                        <p>No offers available.</p>
                      )}</div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-3"></div>
                  <div className="col-lg-9">
                    <div className="advantage-icons advantage-icons_1">
                      <div className="d-flex free-icon">
                        <div>
                          <div className="d-flex image-1">
                            <img src={trial} alt="icon-1" />
                          </div>
                          <p >100 NIGHTS  FREE TRIAL</p>
                        </div>
                        <div >
                          <div className="d-flex image-1">
                            <img src={warranty} alt="icon-2" />
                          </div>
                          <p>15 YEARS  WARRANTY</p>
                        </div>
                        <div >
                          <div className="d-flex image-1">
                            <img src={delivery} alt="icon-3" />
                          </div>
                          <p>FREE SHIPPING</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
           
              <div className="content-des mt-5 description_2">
                  <h4 className="mb-3 single_description">Description for {product.title}</h4>
                  {product.descriptions && product.descriptions.length > 0 ? (
                    <ul>
                      {product.descriptions.map((description, index) => (
                        <li key={index}>{description}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>No description available.</p>
                  )}
                  {product && product.category === 'mattress' && product.descriptions ? (
                    <div>
                      <h4 className="mb-3 single_description">Description for {selectedCover}</h4>
                      <ul>
                        {selectedCoverDescriptions.length > 0 ? (
                          selectedCoverDescriptions.map((description, index) => (
                            <li key={index}>{description}</li>
                          ))
                        ) : (
                          <li>No cover description available.</li>
                        )}
                      </ul>
                    </div>
                  ) : null}
                </div>
              <Reviews />
              {category !== 'pillow' && category !== 'Kids-Pillow' ? (
                <Specifications />
              ) : (
                <Faqs />
              )}

              {sideBarshow && <Addtocart handleCloseSideNav={handleCloseSideNav} sideBarshow={sideBarshow} ></Addtocart>}
            </div>
          </div>
        </div>
      )
      }
    </div >
  );
}  
