import React from 'react';
import Lottie from 'lottie-react';
import successAnimation from './Animation - 1715927655807 .json'; // Downloaded from LottieFiles


const PaymentSuccessAnimation = () => {
  return (
    <div className="animation-container">
      <Lottie animationData={successAnimation} loop={false} className="lottie-animation" />
    </div>
  );
};

export default PaymentSuccessAnimation;
