import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
function Faqs() {
  return (
    <div>  <Accordion defaultActiveKey="0" className="mb-5">
    <div className="specifications-heading"><h1 className='font_family'>Pillows FAQ's</h1></div>
      <Accordion.Item eventKey="0">
        <Accordion.Header> Which pillow should we buy- Regular,Contour</Accordion.Header>
        <Accordion.Body>
          We have 2 types of pillows Regular and Contour. Recommending pillows depends on the kind of sleeper,
          whether side, back or stomach and whether you have neck pain. If you have neck pain/spondylitis, or if
          you are a side sleeper use Contour. If you like plush, go for our Shredded. If you are a back or
          stomach sleeper , you may use the regular one(best for back and stomach sleepers). The regular one is
          medium firm while contour Pillow is soft. Regular is 55 density medium firm and with 5 thickness while
          contour has 2 heights 8 cm and 10 cm. Contour pillow 45 density soft 3 thickness.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header> I would like to know the Density and ILD of the Foam used in the regular pillow</Accordion.Header>
        <Accordion.Body>
        Pillow also has Certified Natural latex with density being 55 for regular pillow and 50 for contour
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Which pillow is popular among elderly customers</Accordion.Header>
        <Accordion.Body>
        The contour one has got a better demand among the elders
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Will the holes in the pillow make them sag n sink sooner?</Accordion.Header>
        <Accordion.Body>
        Holes are for air circulation. Pincore holes in latex help in dissipating heat and these will not
            lead to sagging issues. Our pillows are tested for that and thus we provide 3 years warranty against
            sagging.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>What is the gsm used for pillow cover?</Accordion.Header>
        <Accordion.Body>
        280 gsm knitted fabric with enhanced breathability. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>Is pillow cover water proof?</Accordion.Header>
        <Accordion.Body>
        The pillow cover is only washable not waterproof.We do not recommend using waterproof protectors as
            they block air circulation through the pillow .
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>Do you have warranty for pillows</Accordion.Header>
        <Accordion.Body>
        All Livon Comforts Natural Latex pillows come with a 3-year warranty.  
        </Accordion.Body>
      </Accordion.Item>
    </Accordion></div>
  )
}

export default Faqs;