import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import BounceLoader from "react-spinners/BounceLoader";
import {  useNavigate } from 'react-router-dom';
import './Login'; // Import styles if needed
import './Header';
import baseURL from '../Environment';
export default function QuizQuestions() {
  // State variable to keep track of the current question number
  const [currentQuestion, setCurrentQuestion] = useState(1);

  // State variable to indicate if the data is still loading
  const [loading, setLoading] = useState(true);

  // State variable to store the selected options for each question
  const [selectedOptions, setSelectedOptions] = useState([]);

  // State variable to store the content (questions) fetched from the server
  const [content, setContent] = useState([]);

  // State variable to store the options for each question fetched from the server
  const [options, setOptions] = useState([]);

  // State variable to toggle between quiz and options
  const [showQuiz, setShowQuiz] = useState(true);

  // State variable to store the options selected by the user
  const [getoptions, setGetoptions] = useState(null);




  // Fetch data from the server
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(baseURL + 'options');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setContent(data.map(item => item.question)); // Set the content (questions) fetched from the server
        setOptions(data.map(item => item.options)); // Set the options for each question fetched from the server
        setSelectedOptions(new Array(data.length).fill(0)); // Initialize selected options array with 0 for every question

        // Simulate a loading delay of 2 seconds
        setTimeout(() => {
          setLoading(false); // Set loading to false to indicate that the data has finished loading
        }, 2000);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false in case of an error
      }
    };

    fetchData(); // Call the fetchData function when the component mounts
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  // Function to navigate to the previous question
  const goToPreviousQuestion = () => {
    if (currentQuestion > 1) {
      setCurrentQuestion(prevQuestion => prevQuestion - 1);
    }
  };

  // Function to navigate to the next question
  const goToNextQuestion = () => {
    if (currentQuestion < content.length) {
      setCurrentQuestion(prevQuestion => prevQuestion + 1);
    }
  };

  // Function to handle option click
  const handleOptionClick = (optionIndex) => {
    // Create a copy of the selectedOptions array
    const newSelectedOptions = [...selectedOptions];
    // Update the selected option for the current question
    newSelectedOptions[currentQuestion - 1] = optionIndex;
    // Update the selectedOptions state with the new array
    setSelectedOptions(newSelectedOptions);

    // Reset border styles for all options in the current question
    const optionButtons = document.querySelectorAll('.option-button');
    optionButtons.forEach((button, index) => {
      if (index === optionIndex) {
        // Add 'selected' className to the button if it is the selected option
        button.classList.add('selected');
      } else {
        // Remove 'selected' className from the button if it is not the selected option
        button.classList.remove('selected');
      }
    });

    // Navigate to the next question
    goToNextQuestion();
  };

  // Function to handle form submission
  const handleSubmit = async () => {
    try {
      // Format selected options data
      const formattedOptionsData = selectedOptions.map((optionIndex, questionIndex) => {
        const questionNumber = `Question_${questionIndex + 1}`;
        const selectedOption = options[questionIndex][optionIndex].label;
        return `${questionNumber}: ${selectedOption}`;
      });

      // Log the formatted options data
      console.log('Formatted options data:', formattedOptionsData);

      // Send the formatted options data to the server
      const response = await fetch(baseURL + 'options/selectoptions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ selectedOptions: formattedOptionsData })
      });

      // Check if the request was successful
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    
      // Log the response from the server
      const responseData = await response.json();
      console.log('Response from server:', responseData);

      // Handle the response as needed

      // Set showQuiz to false to hide quiz and show options
      setShowQuiz(false);
      
    } catch (error) {
      console.error('Error submitting selected options:', error);
    }
  };

  useEffect(() => {
    // Function to fetch options from the server
    const fetchOptions = async () => {
      try {
        // Send a GET request to the server to fetch options
        const response = await fetch(baseURL + 'options/getoptions', {
          headers: {
            'Cache-Control': 'no-cache'
          }
        });
        // Parse the response data as JSON
        const data = await response.json();
        // Set the fetched options in the state
        setGetoptions(data[0]);
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };
    // Call the fetchOptions function when the component mounts
    fetchOptions();
  }, []);

  // Create a navigate function using the useNavigate hook
  const navigate = useNavigate();

  const navigateToSingleProductDetails = (getoptions) => {
    // Replace spaces with hyphens in the title
    const titleWithHyphens = getoptions.title.replace(/\s+/g, '-');
    // Navigate to /product/category/id/title-with-hyphens
    navigate(`/product/${getoptions.category}/${getoptions._id}/${titleWithHyphens}`);
  };

  const handleBackToQuiz = () => {
    // Set showQuiz to true to show the quiz content
    setShowQuiz(true);
  };



  return (
    <>
      {showQuiz ? (
        <div className={`quiz-conte${loading ? ' loading' : ''}`}>
          {loading ? (
            <div className="sweet-loading">
              {/* <PacmanLoader color="white" /> */}
              <BounceLoader color="white" />
            </div>
          ) : (
            <div className='container contain-top'>
              <div className='row sec-1'>
                <div className='col-lg-9 steps-ques'>
                  {content.map((_, index) => (
                    <button
                      key={index}
                      className={currentQuestion === index + 1 ? 'active' : ''}
                      onClick={() => setCurrentQuestion(index + 1)}
                    />
                  ))}
                </div>
                <div className="col-lg-3 pre-next">
                  <button onClick={goToPreviousQuestion}><FontAwesomeIcon icon={faArrowLeft} className="font-awesome-icon" /></button>
                  <button onClick={goToNextQuestion}><FontAwesomeIcon icon={faArrowRight} className="font-awesome-icon" /></button>
                </div>
              </div>
              <div>
                <p className='ques'>{content[currentQuestion - 1]}</p>
                {currentQuestion > 0 && currentQuestion <= options.length && (
                  <div className=" row options mt-5">
                    {options[currentQuestion - 1].map((option, index) => (
                      <div key={index} className="col-lg-2 option-item">
                        <img src={option.imageSrc} alt={`Option ${index + 1}`} /><br />
                        <button
                          className={`option-button ${selectedOptions[currentQuestion - 1] === index ? 'selected' : ''}`}
                          onClick={() => handleOptionClick(index)}
                        >
                          {option.label}
                        </button>
                      </div>
                    ))}
                  </div>
                )}
                {/* Render the submit button only on the last question */}
                {currentQuestion === content.length && selectedOptions.every(option => option !== null) && (
                  <div className="submit-container">
                    <button className="submit-button" onClick={handleSubmit}>
                      Submit
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className='contain-top'>
          <div className='container getoptions '>
            <div className="pre-next">
              <button onClick={handleBackToQuiz}><FontAwesomeIcon icon={faArrowLeft} className="font-awesome-icon" /></button>
              <span className='backToquiz'>Click here to edit your anwers.</span>
            </div>
            <h2 className='mt-3'>Your perfect mattress is for</h2>
            {getoptions && (
              <div>
                <div className='d-flex detail-option'>
                  <div>
                    <ul>
                      <li><b>Age Group:</b> <span className='selected-answers'>{getoptions.selectedOptions.find(option => option.startsWith('Question_1')).split(':')[1].trim()}</span></li>
                      <li><b>Sleeping Position: </b><span className='selected-answers'>{getoptions.selectedOptions.find(option => option.startsWith('Question_2')).split(':')[1].trim()}</span></li>
                      <li><b>Thickness:</b> <span className='selected-answers'>{getoptions.selectedOptions.find(option => option.startsWith('Question_4')).split(':')[1].trim()}</span></li>
                    </ul>
                  </div>
                  <div>
                    <ul>
                      <li><b>Mattress For:</b> <span className='selected-answers'>{getoptions.selectedOptions.find(option => option.startsWith('Question_3')).split(':')[1].trim()}</span></li>
                      <li><b>Problem:</b> <span className='selected-answers'>{getoptions.selectedOptions.find(option => option.startsWith('Question_5')).split(':')[1].trim()}</span></li>
                      <li><b>Firmness:</b><span className='selected-answers'>{getoptions.selectedOptions.find(option => option.startsWith('Question_6')).split(':')[1].trim()}</span></li>
                    </ul>
                  </div>
                </div>
                <div>
                  {getoptions.products && getoptions.products.length > 0 ? (
                   <div className='p-2'> 
                     <div className='row product-sug'>
                      {getoptions.products.map((dataObj, index) => (
                        <div key={index} className='col-lg-4 card '>
                          <img src={dataObj.imageurl[0]} alt='mattress-product' />
                          <div className='sug-but'>
                            <h3>{dataObj.title}</h3>
                            <ul>
                              {dataObj.descriptions && dataObj.descriptions.length > 0 ? (
                                dataObj.descriptions.slice(0, 2).map((description, index) => (
                                  <li key={index}>{description}</li>
                                ))
                              ) : (
                                <li className="mattress-description">No description available.</li>
                              )}
                            </ul>
                            <button onClick={() => { navigateToSingleProductDetails(dataObj) }}>Buy Now</button>
                          </div>
                        </div>
                      ))}
                    </div>
                   </div>
                  ) : (
                    <div>No products available</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
