import React, { useEffect, useState } from 'react';
import './Orders.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import useUserId from './UseUserId'; // Import the custom hook
import baseURL from '../Environment';

const OrdersPage = () => {
  // State variables
  const [userOrders, setUserOrders] = useState(null); // Store user orders
  const [displayedOrders, setDisplayedOrders] = useState(2); // Initially display 3 orders
  const [showAllOrders, setShowAllOrders] = useState(false); // Track whether all orders are displayed
  const userId = useUserId(); // Get the user ID using a custom hook

  useEffect(() => {
    // Fetch user orders using the userId
    const fetchUserOrders = async () => {
      try {
        const response = await fetch(baseURL + `orders/getorders/${userId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch user orders');
        }
        const data = await response.json();
        setUserOrders(data);
      } catch (error) {
        console.error('Error fetching user orders:', error);
        setUserOrders([]); // Set userOrders to an empty array in case of an error
      }
    };

    // Fetch user orders only if userId is truthy
    if (userId) {
      fetchUserOrders();
    }
  }, [userId]); // Include userId in the dependency array

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  // Check if userOrders is not an array
  if (!Array.isArray(userOrders)) {
    return <p className='orderpage_no-orders'>No orders available</p>;
  }
  // If userOrders is an empty array and userId is not null or undefined, display the message
  if (userOrders.length === 0 && userId) {
    return <p className='orderpage_no-orders'>No orders found for you</p>;
  }

  // Function to format a date string
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  // Function to handle toggling the display of all orders
  const handleToggleOrders = () => {
    setShowAllOrders(!showAllOrders); // Toggle showAllOrders state
    if (!showAllOrders) {
      setDisplayedOrders(userOrders.length); // Display all orders
    } else {
      setDisplayedOrders(2); // Display only 3 orders
    }
  };

  return (
    <>
      <div className='container order-page'>
        <h2 className='order-page_para mb-4'>Orders</h2>
      </div>
      {userOrders &&
        userOrders
          .slice()
          .reverse()
          .slice(0, displayedOrders)
          .map((order, index) => (
            <div key={index}>
              <div className="container order-data mb-3 order-page_data" style={{ padding: '20px 32px' }}>
                <div className="row main mb-4 order_details">
                  <div className="col-md-4">
                    <div className="mt-3">
                      <p className="text-xs fontfamily">ORDER NUMBER</p>
                    </div>
                    <div>
                      <p className="order-number my-3">{order.orderId}</p>
                    </div>
                  </div>
                  <div className="col-md-4 text-center">
                    <div className="mt-3">
                      <p className="text-xs">ORDER PLACED</p>
                    </div>
                    <div>
                      <p className="order-number my-3">{formatDate(order.createdAt)}</p>
                    </div>
                  </div>
                  <div className="col-md-4 text-center mt-3">
                    <Link to={`/account-detail/product/${order.orderId}`}>
                      <button className="view-details">VIEW DETAILS</button>
                    </Link>
                  </div>
                </div>
                {/* {/ Display the first item in the order /} */}
                {order.products.length > 0 && (
                  <div className="row main-2">
                    <div className="col-md-8 main-2_col" >
                      <div className='row'>
                        <div className='col-lg-4'>
                          <img src={order.products[0].imageurl[0]} alt={order.products[0].title} />
                        </div>
                        <div className='col-lg-8 mt-4'>
                          <h2 className='my-3'>{order.products[0].title}</h2>
                          <div className='dimen mt-2'>
                            {order.products[0].selectedCategoryType !== null && order.products[0].selectedCategoryType !== undefined && order.products[0].selectedCategoryType !== "" &&
                              <p className="line" style={{ color: 'rgb(163 204 207)', fontSize: '13px' }}>Size:
                                <span className="text-dark">{order.products[0].selectedCategoryType}</span></p>
                            }
                             {order.products[0].selectedCover !== null && order.products[0].selectedCover !== undefined && order.products[0].selectedCover !== "" &&
                              <>
                                <p className="line"  style={{ color: 'rgb(163 204 207)', fontSize: '13px' }}>Covers:
                                <span className="text-dark">{order.products[0].selectedCover}</span></p>
                                
                               </>}
                             
                              <p className="line lines-2" style={{ color: 'rgb(163 204 207)', fontSize: '13px' }}>Dimensions:
                                <span className="text-dark">{order.products[0].selectedDimension}</span></p>
                         
                            {order.products[0].selectedHeight !== null && order.products[0].selectedHeight !== undefined && order.products[0].selectedHeight !== "" &&
                              <p className='lines' style={{ color: 'rgb(163 204 207)', fontSize: '12px' }}>
                                Height: <span className="text-dark">{order.products[0].selectedHeight} inches</span></p>
                            }
                          
                           
                            {order.products[0].selectedPackOf !== null && order.products[0].selectedPackOf !== undefined && order.products[0].selectedPackOf !== "" &&
                              <p style={{ color: 'rgb(163 204 207)', fontSize: '13px' }}>Pack of pillows:<span className="text-dark">{order.products[0].selectedPackOf}</span></p>
                            }

                          </div>
                          <div className="quantity">
                            <p className="sub-quantity">Qty: <span className="product-quantity" style={{ color: 'rgb(66 86 87)' }}>{order.products[0].quantity}</span></p>
                            {order.products.length > 1 && (
                              <p className="sub-quantity ">
                                <Link to={`/account-detail/product/${order.orderId}`} className='items-list'><span>Items: </span>{order.products.length - 1}+</Link>
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="col-lg-4 mt-4 status">
                      <div className=" payment-status">
                        <p className="capitalize">
                          <span>{order.paymentMethod}</span>
                        </p>
                      </div>
                      <div className="pending-payment">
                        <p className="fw-bold price">₹{order.products[0].originalPrice}/-</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
      {userOrders && userOrders.length > 2 && (
        <button className="view-toggle-btn" onClick={handleToggleOrders}>
          {showAllOrders ? (
            <>
              View Less <FontAwesomeIcon icon={faChevronUp} />
            </>
          ) : (
            <>
              View More <FontAwesomeIcon icon={faChevronDown} />
            </>
          )}
        </button>
      )}
    </>
  );
};

export default OrdersPage;
