import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './Header.css';
import { useNavigate } from 'react-router-dom';
import baseURL from "../Environment";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function TopperProducts() {
  // Define the responsive object for the Carousel component
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
    desktop: { breakpoint: { max: 3000, min: 1440 }, items: 4 },
    laptop:{breakpoint: { max: 1440, min: 1024 }, items: 3 },
    tablet: { breakpoint: { max: 1024, min: 767 }, items: 2 },
    mobile: { breakpoint: { max: 767, min: 0 }, items: 1 }
  };
  // Define the navigate function from the react-router-dom package
  const navigate = useNavigate();
  // Define the state variable 'data' and its setter function 'setData'
  const [data, setData] = useState([]);

  // Fetches the information for the toppers from the server
  const fetchInfo = () => {
    return fetch(baseURL + 'productdetails/toppers')
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch(error => console.error('Error:', error));
  };
  
  // Calls the fetchInfo function when the component mounts
  useEffect(() => {
    fetchInfo();
  }, []);

  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Initialize the AOS library for animations
  useEffect(() => {
    AOS.init({
        duration: 1000, // duration of the animation in milliseconds
    });
}, []);

  const navigateToSingleProductDetails = (data) => {
    // Replace spaces with hyphens in the title
    const titleWithHyphens = data.title.replace(/\s+/g, '-');
    // Navigate to /product/category/id/title-with-hyphens
    navigate(`/product/${data.category}/${data.id}/${titleWithHyphens}`);
  };
  return (
    <div className='topper_products' id='topper-products'>
      <div className='container-fluid'>
        <div className='mat-heading topper_heading' >
          <h2> Our Toppers Range</h2>
          <p>Make your existing mattress reborn by adding a Natural 100% Organic Latex topper layer and make your sleep time 70% more hygienic!</p>
        </div>
        <Carousel responsive={responsive}>
          {data.map((dataObj, index) => (
            <div className='pcard p_card2 product' key={dataObj.id}>
              <img src={dataObj.imageurl[0]} alt='mattress product' className='product-image' />
              <Card.Body>
              <div className="desc-gap">
                <Card.Title>{dataObj.title}</Card.Title>
                <ul>
                  {dataObj.descriptions && dataObj.descriptions.length > 0 ? (
                    dataObj.descriptions.slice(0, 2).map((description, index) => (
                      <li key={index} className="mattress-description">{description}</li>
                    ))
                  ) : (
                    <li className="mattress-description">No description available.</li>
                  )}
                </ul>
                </div>
                <Button onClick={() => { navigateToSingleProductDetails(dataObj) }}>Buy Now</Button>
              </Card.Body>

            </div>
          ))}
        </Carousel>
      </div>

    </div>
  )
}
