
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './Header.css';
import baseURL from "../Environment";
import AOS from 'aos';
import 'aos/dist/aos.css';


export default function MattressProductList() {
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
    desktop: { breakpoint: { max: 3000, min: 1440 }, items: 4 },
    laptop: { breakpoint: { max: 1440, min: 1024 }, items: 3 },
    tablet: { breakpoint: { max: 1024, min: 767 }, items: 2 },
    mobile: { breakpoint: { max: 767, min: 0 }, items: 1 }
  };

  const [data, setData] = useState([]);
  const navigate = useNavigate();

  // Fetch mattress product details from the server
  const fetchInfo = () => {
    return fetch(baseURL + 'productdetails/mattress')
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch(error => console.error('Error:', error));
  };

  // Call fetchInfo function when the component mounts
  useEffect(() => {
    fetchInfo();
  }, []);
  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigateToSingleProductDetails = (data) => {
    // Replace spaces with hyphens in the title
    const titleWithHyphens = data.title.replace(/\s+/g, '-');
    // Navigate to /product/category/id/title-with-hyphens
    navigate(`/product/${data.category}/${data.id}/${titleWithHyphens}`);
  };
  // Initialize AOS library for animations
  useEffect(() => {
    AOS.init({
      duration: 1000, // duration of the animation in milliseconds
    });
  }, []);
  return (
    <div className="mattress-products" id='Mattress-Products'>
      <div className='container-fluid'>
        <div className='mat-heading mat_heading' >
          <h2> Our Mattress Range</h2>
          <p>Our natural latex mattresses are entirely eco-friendly and long-lasting. Experience sleeping on cleaner and organic materials.</p>
        </div>
        <Carousel responsive={responsive}>
          {data.map((dataObj, index) => (
            <div className='pcard product' key={dataObj.id}>
              {dataObj.latestproduct === "new" && <div className="new-product-label new"><p>New</p></div>}
              <img src={dataObj.imageurl[0]} alt='mattress product ' className='product-image' />
              <Card.Body>
                <div className="desc-gap">
                  <Card.Title>{dataObj.title}</Card.Title>
                  {dataObj.descriptions && dataObj.descriptions.length > 0 ? (
                    <ul>
                      {dataObj.descriptions.slice(0, 2).map((description, index) => (
                        <li key={index} className="mattress-description">{description}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>No description available.</p>
                  )}
                </div>
                <Button onClick={() => { navigateToSingleProductDetails(dataObj) }}>Buy Now</Button>
              </Card.Body>
            </div>
          ))}
        </Carousel>

      </div>
    </div>
  );
}
