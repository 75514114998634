import { useState, useEffect } from 'react';
import { decodeToken } from 'react-jwt';// Import your token decoding library

const useUserId = () => {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    // Retrieve token from local storage
    const token = localStorage.getItem('token');

    if (token) {
      // Decode the token and extract user ID
      const decodedToken = decodeToken(token);
      const extractedUserId = decodedToken?.userId;

      setUserId(extractedUserId);
    }
  }, []);

  return userId;
};

export default useUserId;
