import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Rating from 'react-rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import './Reviews.css';
import useUserId from './UseUserId'; // Import the custom hook
import baseURL from '../Environment';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    // Set the maximum width to 100% for all screen sizes
    '& .MuiPaper-root': {
        maxWidth: '100%',
    },
}));

export default function Reviews() {
    const [open, setOpen] = React.useState(false);
    const { id } = useParams();
    const [reviews, setReviews] = useState([]);
    // States to store the user's rating and textarea text
    const [userRating, setUserRating] = useState(0);
    const [textareaText, setTextareaText] = useState('');
    const [themeText, setThemeText] = useState('');
    const [editReviewId, setEditReviewId] = useState(null);
    const [totalAverageRating, setTotalAverageRating] = useState(null);
    const userId = useUserId(); // Get the user ID using a custom hook
    const productId = id;

    // Function to format a date string into a readable format
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    // Function to handle click event and open the dialog
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false); // Close the dialog
        setUserRating(""); // Reset the user's rating
        setTextareaText(""); // Reset the textarea text
        setThemeText(""); // Reset the theme text
    };


    // Function to submit the user's rating and review
    const submitRating = async () => {
        try {
            // Check if any required field is empty
            if (!userRating || !themeText || !textareaText) {
                alert("Please fill in all the fields before submitting your review.");
                return;
            }

            if (editReviewId === null) {
                // Posting a new review
                const response = await fetch(baseURL + `review/${userId}/${productId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ rating: userRating, comment: textareaText, theme: themeText }),
                });
                setUserRating("");
                setTextareaText("");
                setThemeText("");
                // Handle response from API if needed
                const data = await response.json();
                console.log('Response from API (Submit Review):', data);
                handleClose(); // Close the dialogue box after update
            } else {
                // Updating an existing review
                const response = await fetch(baseURL + `review/${userId}/${editReviewId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ rating: userRating, comment: textareaText, theme: themeText }),
                });
                const data = await response.json();
                console.log('Response from API (Update Review):', data);
                if (response.status === 403) {
                    alert('You are not authorized to update this rating/comment');
                } else {
                    // Success scenario
                    fetchReviews(); // Fetch updated reviews
                    handleClose(); // Close the dialogue box after update
                    alert('Comment updated successfully');
                }
            }
        } catch (error) {
            console.error('Error submitting/updating rating:', error);
        }
    };


    //handle rating 
    const fetchReviews = () => {
        fetch(baseURL + 'review/user-comments-ratings')
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    // Flatten the ratings array and add fullname to each review
                    const flattenedReviews = data.users.flatMap(user => {
                        return user.ratings.map(rating => {
                            return {
                                ...rating,
                                fullname: user.fullname,
                                averageRating: user.averageRating,
                                totalReviews: user.totalReviews,
                            };
                        });
                    });
                    setReviews(flattenedReviews);
                    setTotalAverageRating(data.totalAverageRating);
                } else {
                    console.error('Failed to fetch reviews');
                }
            })
            .catch(error => {
                console.error('Error fetching reviews:', error);
            });
    };

    useEffect(() => {
        // Fetch reviews from API endpoint
        fetchReviews();
    }, []);

    // Handler function to edit a review
    const handleEditReview = (review) => {
        setUserRating(review.rating); // Set the user's rating to the rating of the review being edited
        setTextareaText(review.comment); // Set the textarea text to the comment of the review being edited
        setThemeText(review.theme); // Set the theme text to the theme of the review being edited
        setOpen(true); // Open the dialog for editing the review
        setEditReviewId(review._id); // Store the ID of the review being edited
    };
    // Handler function to update user's rating
    const handleRatingChange = (rating) => {
        setUserRating(rating);
        console.log('User rating:', rating);
    };
    const handleThemeChange = (event) => {
        setThemeText(event.target.value);
        console.log('Theme text:', event.target.value);
    };

    // Handler function to update textarea text
    const handleTextareaChange = (event) => {
        setTextareaText(event.target.value);
        console.log('Textarea text:', event.target.value);
    };

    // Function to determine the color based on rating
    const getStarColor = (rating) => {
        if (rating >= 4) {
            return '#255852'; // Green color for high ratings
        } else if (rating >= 3) {
            return 'orange'; // Yellow color for moderate ratings
        } else {
            return '#255852'; // Red color for low ratings
        }
    };
    const getHoverColor = (rating) => {
        if (rating < 2) {
            return 'red'; // Red color if rating is less than 2
        } else if (rating < 4) {
            return 'orange'; // Orange color if rating is less than 4
        } else {
            return '#255852'; // Green color if rating is greater than or equal to 4
        }
    };

    return (

        <React.Fragment>
            <div className='row mt-5 reviews-section'>
                <div className='col-lg-4 '>
                    <h3>Reviews</h3>
                    {userId && (
                        <Button onClick={handleClickOpen} className='reviwe-button mb-3 mt-3'>
                            Write a Review
                        </Button>
                    )}
                    <div>
                        <p className='mb-3'>Filter By Rating</p>
                        <div className='mt-3 mb-5'>
                            <Rating
                                fractions={2}
                                initialRating={4.5}
                                readonly
                                emptySymbol={<FontAwesomeIcon icon={regularStar} />}
                                fullSymbol={<FontAwesomeIcon icon={solidStar} />}
                                style={{ color: getStarColor(userRating) }}
                            /> {totalAverageRating && (
                                <span>{totalAverageRating}/5</span>
                            )}</div>
                    </div>
                </div>
            </div>
            <hr />
            <div>
                {reviews.map((review, index) => (
                    <div key={index} className='row mt-3 user-reviws-section'>
                        <div className='col-lg-2 review-user'>
                            <Rating
                                initialRating={review.rating}
                                readonly
                                emptySymbol={<FontAwesomeIcon icon={regularStar} />}
                                fullSymbol={<FontAwesomeIcon icon={solidStar} />}
                                style={{ color: getStarColor(userRating) }}
                            />
                            <h3 className='mt-2 mb-2 review-name'>{review.fullname}</h3>                
                            <p>{formatDate(review.createdAt)}</p>
                        </div>
                        <div className='col-lg-10'>
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 className='comment-theme'>{review.theme}</h5>
                                <div>{review.userId === userId && (
                                    <button onClick={() => handleEditReview(review)} className='edit-review'>
                                        <FontAwesomeIcon icon={faPenToSquare} className='editIcon' />
                                    </button>
                                )}</div>
                            </div>

                            <p className='comment-para'>{review.comment}</p>
                        </div>
                        <hr className='mt-4' />
                    </div>
                ))}

            </div>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Write a Review
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <Rating
                            fractions={2}
                            initialRating={userRating}
                            emptySymbol={<FontAwesomeIcon icon={regularStar} />}
                            fullSymbol={<FontAwesomeIcon icon={solidStar} />}
                            onChange={handleRatingChange} // Call handleRatingChange when rating changes
                            className="custom-rating"
                            style={{ color: getStarColor(userRating) }}
                            hoverColor={getHoverColor(userRating)}
                        /> choose a rating
                    </Typography>
                    <Typography gutterBottom>
                        <label>Title</label>
                        <input className='review-theme' value={themeText} onChange={handleThemeChange} />
                    </Typography>
                    <Typography gutterBottom>
                        <label>Review</label><br />
                        <textarea value={textareaText} onChange={handleTextareaChange} placeholder="Give Your valuable Feedback...." className='comment-review'></textarea>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className=' text-white dia-action'>
                        Discard
                    </Button>
                    <Button onClick={submitRating} className=' text-white dia-action'>Submit</Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}
