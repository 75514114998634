import React, { createContext, useState, useEffect } from 'react';

// Create a context for the cart data
export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  // Initialize cart items count state
  const [cartItemsCount, setCartItemsCount] = useState(0);
  useEffect(() => {
    // Retrieve cart items count from local storage on component mount
    const storedCartItemsCount = localStorage.getItem('cartItemsCount');
    if (storedCartItemsCount) {
      setCartItemsCount(parseInt(storedCartItemsCount));
    }
  }, []);

  // Function to update the cart items count
  const updateCartItemsCount = (count) => {

    setCartItemsCount(count);
    // Store cart items count in local storage
    localStorage.setItem('cartItemsCount', count);
  };
  
  return (
    <CartContext.Provider value={{ cartItemsCount, updateCartItemsCount }}>
      {children}
    </CartContext.Provider>
  );
};
