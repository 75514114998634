import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import './Orderdetails.css';
import baseURL from "../Environment";
import useUserId from './UseUserId'; // Import the custom hook
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify'; // Import the Bounce transition effect

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    width: '30%', // Adjust the width as needed
    maxWidth: 'none', // Remove the maximum width
  },

  // Media query for screens smaller than or equal to 768px
  '@media (max-width: 768px)': {
    '& .MuiDialog-paper': {
      width: '90%', // Adjust the width for smaller screens
    },
  },
  // Media query for screens between 769px and 1024px
  '@media (min-width: 769px) and (max-width: 1024px)': {
    '& .MuiDialog-paper': {
      width: '60%', // Adjust the width for screens between 769px and 1024px
    },
  },
  // Media query for screens between 769px and 1024px
  '@media (min-width: 1025px) and (max-width: 1440px)': {
    '& .MuiDialog-paper': {
      width: '40%', // Adjust the width for screens between 769px and 1024px
    },
  },
}));


const Orderdetails = ({ sendDataToParent, sendTotalAmountToParent }) => {

  const [totalAmount, setTotalAmount] = useState(0);
  const [products, setProducts] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [coupons, setCoupons] = React.useState([]);
  const [couponInput, setCouponInput] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState(0); // Initialize discounted price state
  const [couponApplied, setCouponApplied] = useState(false); // Initialize coupon applied state
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  //dynamic userId
  const userId = useUserId();

  //coupon get
  React.useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await fetch(
          baseURL+'coupon'
        );
        const data = await response.json();
        setCoupons(data.coupons);
      } catch (error) {
        console.error('Error fetching coupons:', error);
      }
    };
    fetchCoupons();
  }, []);

  //coupen dialog box open
  const handleClickOpen = () => {
    setOpen(true);
  };
  //coupen dialog box close
  const handleClose = () => {
    setOpen(false);
  };


  //apply coupon
  const handleApplyCoupon = (coupon, event) => {
    if (event) {
      event.preventDefault(); // Prevent the default form submission behavior
    }
    // Check if a coupon has already been applied
    if (couponApplied) {
      toast.error('Coupon applied already!', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
      return;
    }

    if (couponInput) {
      const existingCoupon = coupons.find((c) => c.coupon === couponInput);
      if (!existingCoupon) {
        toast.error('Invalid coupon code!', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
        setCouponInput('');
        return;
      }
      coupon = existingCoupon;
    }

    // Remove any previously applied coupon

    setDiscountedPrice(0);
    setCouponApplied(false);
    setAppliedCoupon(null);

    // Extract the last two digits from the coupon code
    const lastTwoDigits = parseInt(coupon.coupon.slice(-2));
    // Calculate the discount percentage based on the last two digits
    const discountPercentage = lastTwoDigits;
    // Calculate the discounted amount and price
    const discountedAmount = (totalPrice * discountPercentage) / 100;
    const newDiscountedPrice = totalPrice - discountedAmount;
    // Set the discounted price as the new total amount
    setTotalAmount(newDiscountedPrice);
    setDiscountedPrice(newDiscountedPrice);
    setAppliedCoupon(coupon);
    setCouponApplied(true);
    setOpen(false);
  };

  //coupon remove
  const handleRemoveCoupon = () => {
    setDiscountedPrice(0);
    setCouponApplied(false);
    setAppliedCoupon(null);
    setTotalAmount(totalPrice);
    setCouponInput(''); // Clear the input field
  };


  //handle add quantity
  const handleAdd = (productData, indexNumber) => {
    const updatedProducts = products.map((product, index) => {
      if (index === indexNumber) {
        const updatedCount = product.quantity + 1;
        // Check if updatedCount exceeds 10
        const limitedCount = Math.min(updatedCount, 10);
        const updatedPrice = product.originalPrice * limitedCount;
        return { ...product, quantity: limitedCount, price: updatedPrice.toFixed(2) };
      } else {
        return product;
      }
    });
  
    if (userId) { // User is authenticated, update on server
      fetch(baseURL + `cart/increment/${userId}/${productData.productId}/${productData.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ quantity: 1 }),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          // Handle successful response
          return response.json();
        })
        .then(data => {
          // Handle response data if needed
          // console.log('Response:', data);
        })
        .catch(error => {
          console.error('There was a problem with your fetch operation:', error);
        });
    } else { // User is not authenticated, update locally
      localStorage.setItem(productData.id, JSON.stringify(updatedProducts));
    }
    setProducts(updatedProducts);
    setTotalAmount(totalPrice);
  };
  
  //handle substract quantity
  const handleSubtracts = (productData, indexNumber) => {
    let updatedProducts = [...products]; // Make a copy of products array
    const product = updatedProducts[indexNumber];
    const updatedCount = product.quantity - 1;
    const updatedPrice = product.originalPrice * updatedCount;
    // Update product quantity and price
    updatedProducts[indexNumber] = { ...product, quantity: updatedCount, price: updatedPrice.toFixed(2) };
  
    if (!userId) { // User is not authenticated, update locally
      localStorage.setItem(productData.id, JSON.stringify(updatedProducts));
    }
  
    if (updatedCount === 0) {
      // If quantity becomes 0, remove the product from the cart
      deleted(indexNumber).then((success) => {
        if (success) {
          const updatedProductsFiltered = updatedProducts.filter((_, idx) => idx !== indexNumber);
          setProducts(updatedProductsFiltered);
        }
      });
    } else {
      // Send the updated quantity to the server
      const updatedQuantity = {
        quantity: 1 // Decrementing by 1
      };
      fetch(baseURL + `cart/decrement/${userId}/${productData.productId}/${productData.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedQuantity),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          // Handle successful response
          return response.json();
        })
        .then(data => {
          // Handle response data if needed
          // console.log('Response:', data);
        })
        .catch(error => {
          console.error('There was a problem with your fetch operation:', error);
        });
  
      setProducts(updatedProducts);
      setTotalAmount(totalPrice);
    }
  };
  
  //handle delete product
  const deleted = (index, updateLocalState = true) => {
    return new Promise((resolve, reject) => {
      if (!userId) {
        // If user is not logged in, remove item from local storage directly
        if (index !== null && index !== undefined) {
          // If index is provided, remove item using index
          const productId = products[index].id;
          let cartItems = JSON.parse(localStorage.getItem(productId)) || [];
          if (Array.isArray(cartItems) && cartItems.length > index) {
            cartItems.splice(index, 1);
            localStorage.setItem(productId, JSON.stringify(cartItems));
          }
        }
        // Update cartItemsCount in local storage
        const updatedCartItemsCount = parseInt(localStorage.getItem('cartItemsCount')) - 1;
        localStorage.setItem('cartItemsCount', updatedCartItemsCount.toString());
  
        if (updateLocalState) {
          // Assuming 'products' state contains the list of products, update it after deletion
          const updatedProducts = [...products.slice(0, index), ...products.slice(index + 1)];
          setProducts(updatedProducts); // Update products state after deletion
          setTotalAmount(totalPrice); // Recalculate total amount after deleting the product
        }
        resolve(true); // Item removed successfully
      } else {
        // If user is logged in, send a DELETE request to the backend to delete the item
        const productId = products[index].id;
        fetch(baseURL + `cart/deletefromcart/${userId}/${productId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        }).then(response => {
          if (response.ok && updateLocalState) {
            // Item deleted successfully from the server
            // Assuming 'products' state contains the list of products, update it after deletion
            const updatedProducts = [...products.slice(0, index), ...products.slice(index + 1)];
            setProducts(updatedProducts); // Update products state after deletion
            setTotalAmount(totalPrice);// Recalculate total amount after deleting the product
            
            // Update cartItemsCount in local storage
            const updatedCartItemsCount = parseInt(localStorage.getItem('cartItemsCount')) - 1;
            localStorage.setItem('cartItemsCount', updatedCartItemsCount.toString());
          } else if (!response.ok) {
            // Handle error response from the server
            console.error('Failed to delete item from server');
          }
          resolve(response.ok);
        }).catch(error => {
          // Handle network errors
          console.error('Network error:', error);
          reject(false);
        });
      }
    });
  };
  

  //order details get
  const fetchInfo = () => {
    if (userId) {
      // Function to fetch cart data from server
      const fetchCartData = () => {
        fetch(baseURL + `cart/getcart/${userId}`)
          .then((response) => response.json())
          .then((data) => {
            // console.log("Ordered Data:", data);
            setProducts(data.products);
          })
          .catch((error) => {
            console.error("Error fetching data: ", error);
          });
      };
      // Fetch data from server if user is logged in
      fetchCartData();
      // Post locally stored items to server
      const cartItems = [];
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        try {
          const item = JSON.parse(localStorage.getItem(key));
          if (Array.isArray(item)) {
            cartItems.push(...item);
          } else if (item) {
            cartItems.push(item);
          }
        } catch (error) {
          console.error('Error parsing local storage data:', error);
        }
      }
      // Post each item to server
      const postItemPromises = cartItems.map((item) => {
        return fetch(baseURL + `cart/addtocart/${userId}/${item.id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(item),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Failed to add item to cart');
            }
            return response.json();
          })
          .then((data) => {
            // Remove the item from local storage after it's successfully added to the cart
            localStorage.removeItem(item.id);
          })
          .catch((error) => {
            console.error('Error adding item to cart:', error);
          });
      });

      // After all items are posted, fetch the cart data again
      Promise.all(postItemPromises)
        .then(() => {
          fetchCartData();
        });
    } else {
      // If user is not logged in, use data from local storage
      const cartItems = [];
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        try {
          const item = JSON.parse(localStorage.getItem(key));
          if (Array.isArray(item)) {
            // Loop through each item in the array and calculate price
            item.forEach(subItem => {
              subItem.price = subItem.originalPrice * subItem.quantity;
              cartItems.push(subItem);
            });
          } else if (item) {
            // Calculate price for single item
            item.price = item.originalPrice * item.quantity;
            cartItems.push(item);
          }
        } catch (error) {
          console.error('Error parsing local storage data:', error);
        }
      }
      setProducts(cartItems); // Assuming this is the correct function call
    }
  };

  useEffect(() => {
    fetchInfo(); // Fetch data regardless of userId state
    setTotalAmount(totalPrice); // Recalculate total amount
  }, [userId]); // Empty dependency array ensures this effect runs only once when the component mounts

  //total price calculation
  const totalPrice = products && products.length > 0 ?
    products.map(product => product.originalPrice * product.quantity).reduce((acc, val) => acc + Number(val), 0)
    : 0;

  useEffect(() => {
    setTotalAmount(totalPrice);
  }, [totalPrice])

  //sending products to parent component 
  useEffect(() => {
    sendDataToParent(products);
  }, [products]);
  //sending total price to parent component
  useEffect(() => {
    sendTotalAmountToParent(totalAmount);
  }, [totalAmount]);
  return (
    <>
      <div className="form col-md-6 mt-5 yourorder_details">
        <form className="m-0">
          <h6 className="text-center yourorder">YOUR ORDER</h6>
          <hr />
          <div className="container">
            <div className="row">
              {products?.map((product,index) => (
                <div key={product.id} className="order-products">
                  <div className='row'>
                    <div className='col-md-4 cart-icon'>
                    <img src={product.imageurl && product.imageurl.length > 0 ? product.imageurl[0] : ''} alt="productImage" className="thumbnailImg" width={100} />
                      <div className="adding">
                        <button className="subtract-btn" type='button' onClick={() => handleSubtracts(product,index)}>
                          -
                        </button>
                        <input type="text" value={product.quantity} className="quantity-input" readOnly />

                        <button className="add-btn" type='button' onClick={() => handleAdd(product,index)}>
                          +
                        </button>
                      </div>
                    </div>
                    <div className='col-md-8 title ml-5'>
                      <p className='title-p'>{product.title}</p>
                      <div className="heading dimensions">
                        <div className='cart-sizes1'>
                        {product.selectedCategoryType !== null && product.selectedCategoryType !== undefined && product.selectedCategoryType !== "" &&
                            <p className='line'>
                              <b>Size:</b> {product.selectedCategoryType}
                            </p>
                          }
                          {product.selectedPackOf !== null && product.selectedPackOf !== undefined && product.selectedPackOf !== "" &&
                            <p className='line'>
                              {product.selectedPackOf === 1 ? "" : <> <b>pack of {product.selectedPackOf} pillows</b></>}
                            </p>
                          }
                           {product.selectedHeight !== null && product.selectedHeight !== undefined && product.selectedHeight !== "" &&
                            <p className='line'>
                              <b>Height:</b> {product.selectedHeight} inches
                            </p>
                          }
                           {product.selectedCover !== null && product.selectedCover !== undefined && product.selectedCover !== "" &&
                            <p className='line'>
                              <b>Cover:</b> {product.selectedCover}
                            </p>
                          }
                          <p>
                            <b className='select_dimensions'>Dimensions:</b> {product.selectedDimension}
                          </p>
                        </div>
                        <div className="deleteBlk">
                          <h6 onClick={() => deleted(index)}> <FontAwesomeIcon icon={faTrashCan} /> Remove</h6>
                          <h4 className='ms-auto'>₹ {(product.originalPrice * product.quantity).toFixed(2)}/-</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="total fw-bold">
                <div className="row m-2 mt-3">
                  <div className="col-md-6 subtotal_p">
                    <p>Subtotal</p>
                  </div>
                  <div className="col-md-6 subtotal_price ">
                    <p ><span>₹</span>{totalPrice.toFixed(2)}/-</p>
                  </div>
                  <div className="col-md-6 mt-2 shipping_p">
                    <p className='shipping'>Shipping</p>
                  </div>
                  <div className="col-md-6 mt-2 free_p">
                    <p>FREE</p>
                  </div>
                  {couponApplied ? (
                    <div className="row">
                      <div className="col-md-6 mt-2">
                        <p>Coupon Discount({appliedCoupon.coupon})</p>
                      </div>
                      <div className="col-md-6 mt-2">
                        <p>{(discountedPrice - totalPrice).toFixed(2)}/-</p> 
                      </div>
                    </div>
                  ) : null}
                  <div className='col-md-6 mt-2'></div>
                  <div className='col-md-6 mt-2 viewoffers_cls'>
                    <h6 className="text-danger viewoffers" onClick={handleClickOpen}>View Offers</h6>
                    <BootstrapDialog
                      onClose={handleClose}
                      aria-labelledby="customized-dialog-title"
                      open={open}
                    >
                      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        Available Offers
                      </DialogTitle>
                      <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <DialogContent dividers>
                        {coupons.map((coupon) => (
                          <div
                            key={coupon._id}
                            className='row mt-2'
                          >
                            <Typography gutterBottom className='col-lg-10'>
                              <b>{coupon.coupon}</b> <br />{coupon.description}
                            </Typography>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleApplyCoupon(coupon)}
                              className='col-lg-2 offer-apply'
                            >
                              Apply
                            </Button>
                          </div>
                        ))}
                      </DialogContent>
                    </BootstrapDialog>
                  </div>
                  <div>
                    <div>
                      {couponApplied ? (
                        <div className="row">
                          <div className="col-md-6 d-flex mb-3 coupon-applied">
                            <p className="text-danger">Coupon Applied  {appliedCoupon.coupon}</p>
                            <button onClick={handleRemoveCoupon} className='coupon-remove'>Remove</button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    {couponApplied ? null : (
                      <div className='view_offers'>
                        <input
                          type="text"
                          placeholder="Coupon Code"
                          className="w-75 p-2 mb-4 border-0"
                          value={couponInput}
                          onChange={(e) => setCouponInput(e.target.value)}
                          required
                        />
                        <button
                          className="coupon"
                          onClick={(event) => {
                            if (couponInput.trim() !== '') {
                              handleApplyCoupon(couponInput, event);
                            }
                          }}
                        >
                          APPLY
                        </button>
                      </div>
                    )}
                  </div>
                  <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    transition={Bounce}
                  />
                  <hr />
                  <div className="row mx-2 mb-4 total_ammount">
                    <div className="col-md-6 total_cls" style={{ fontSize: '18px' }}>
                      <p>TOTAL</p>
                    </div>
                    <div className="col-md-6 subtotal_ammount">
                      <h4 className='subtotal_ammount'><span>₹</span>{totalAmount.toFixed(2)}/-</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

    </>
  )
}

export default Orderdetails;