import React from 'react'

function Specifications() {
    return (
        <div>
            <div className="specifications">
                <div className="specifications-heading"><h1 className='font_family'>SPECIFICATIONS</h1></div>
                <div className="box">
                    <div className="head"> <h3 className='head_title font_family'>COMFORT AND FEEL</h3></div>
                    <p className='specifications_para'>Our medium firm 100% natural latex mattresses have natural latex foam ( 4”/ 6”/ 8”, depending on the size you opt) with 90 kg/m³ density and an ILD of 40. It has a comfort level of 7.5 on the scale of 1 to 10 where 1 is the softest and 10 is the highest firmness.</p>
                    <p>** Custom size mattresses may not be a single block and side pasting may be required. Kindly contact us for any clarification</p>
                    <div className="head"> <h3 className='head_title font_family'>WEIGHT</h3></div>
                    <div className="table table-responsive text-center">
                        <div className="table table-responsive text-center">
                            <table>
                                <tbody>
                                    <tr>
                                        <th></th>
                                        <th>SINGLE</th>
                                        <th>DOUBLE</th>
                                        <th>QUEEN</th>
                                        <th>QUEEN XL</th>
                                        <th>KING</th>
                                    </tr>
                                    <tr>
                                        <th>4 INCH</th>
                                        <td>15kg</td>
                                        <td>20kg</td>
                                        <td>24kg</td>
                                        <td>27kg</td>
                                        <td>30kg</td>
                                    </tr>
                                    <tr>
                                        <th>6 INCH</th>
                                        <td>25kg</td>
                                        <td>33kg</td>
                                        <td>41kg</td>
                                        <td>46kg</td>
                                        <td>50kg</td>
                                    </tr>
                                    <tr>
                                        <th>8 INCH</th>
                                        <td>33kg</td>
                                        <td>44kg</td>
                                        <td>55kg</td>
                                        <td>61kg</td>
                                        <td>66kg</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div className="head" > <h3 className='head_title font_family'>100% NATURAL CERTIFIED LATEX</h3></div>
                    <p className='specifications_para'>Livon Comforts are <a href="">Eco-INSTITUT</a> and <a href="">OEKO- TEX</a> Standard 100 Certified Natural. These certificates are testimony that our mattresses are free of hazardous chemicals and are even suitable for infants. Rest assured, you and your family are completely safe in our all natural mattresses.</p>
                    <div className="head" > <h3 className='head_title font_family'>15 YEAR LIMITED WARRANTY</h3></div>
                    <p className='specifications_para'>Years 1 - 10: Full coverage, Non-Prorated | Years 11-15: Limited Coverage, Prorated</p>
                    <div className="head font_family" > <h3 className='head_title'>SLEEP TRIAL POLICY AND SUCCESS TIPS</h3></div>
                    <p className='specifications_para'>Your 100 nights trial for our latex mattress begins on the day of delivery and it is limited to one mattress per household. Our “Success Tips” help center article provides valuable insights that helps in ensuring, your Livon Comforts experience exceeds the expectations that you have got from us.</p>
                </div>
            </div></div>
    )
}

export default Specifications;