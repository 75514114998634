import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import "react-multi-carousel/lib/styles.css";
import './Header.css';
import baseURL from "../Environment";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function KidsProducts() {
  useEffect(() => {
    // Initialize AOS library for animations
    AOS.init({
        duration: 1000, // duration of the animation in milliseconds
    });
}, []);

  // Function to navigate between pages
  const navigate = useNavigate();

  // State variable to store the fetched data
  const [data, setData] = useState([]);

  // Function to fetch data based on category
  const fetchInfo = () => {
    return fetch(baseURL + 'productdetails/kids Range')
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch(error => console.error('Error:', error));
  };
  useEffect(() => {
    fetchInfo();
  }, []);

  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Function to navigate to single product details page
  const navigateToSingleProductDetails = (data) => {
    const titleWithHyphens = data.title.replace(/\s+/g, '-');
    navigate(`/product/${data.category}/${data.id}/${titleWithHyphens}`);
  };

  return (
    <div className='kids-products' id='kids-products'>
      <div className='container'>
        <div className='kids_heading'>
          <h2> OUR KIDS RANGE</h2>
          <p>Feel the ultimate comfort with our 100% Naturally Certified Latex Kids Mattress and Pillows like never before.</p>
        </div>
        <div className='row'>
          {data.map((dataObj, index) => (
            <div className='col-lg-4' key={index}>
              <div className='card carousel-card kids-carousel'>
                {dataObj.imageurl && dataObj.imageurl.length > 0 && (
                  <Carousel data-bs-theme="dark">
                    {dataObj.imageurl.map((imageurl, idx) => (
                      <Carousel.Item key={idx} className='kid-images'>
                        <img className="d-block w-100" src={imageurl} alt={`Slide ${idx + 1}`} style={{ height: "400px" }} />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                )}
                <Card.Body>
                  
                  <Card.Title>{dataObj.title}</Card.Title>
                  {dataObj.descriptions && dataObj.descriptions.length > 0 ? (
                    <ul>
                      {dataObj.descriptions.slice(0, 2).map((description, index) => (
                        <li key={index} className="mattress-description">{description}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>No description available.</p>
                  )}
                  <Button className='kids-button' onClick={() => navigateToSingleProductDetails(dataObj)}>Buy Now</Button>
                </Card.Body>

              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
