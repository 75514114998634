import React, { useCallback, useState, useEffect } from 'react';
import './Checkout.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Orderdetails from './Orderdetails';
import Alert from 'react-bootstrap/Alert';
import baseURL from '../Environment';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import useRazorpay from "react-razorpay";
import OtpInput from "./OtpInput";
import useUserId from './UseUserId'; // Import the custom hook
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'; // Make sure to import the styles
import companyLogo from './../images/livon comfort logo.png';
import PaymentSuccessAnimation from './PaymentSuccessAnimation';

//stepper info
const steps = [
  'SIGN IN or SIGN UP',
  'ENTER DETAILS',
  'PAYMENT',
];

const Checkout = () => {
  // State variables for phone number, checkbox, address error, and billing email error
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isChecked, setIsChecked] = useState(true);
  const [isAddressError, setIsAddressError] = useState(false);
  const [billingEmailError, setBillingEmailError] = useState(false);

  // State variables for active step, completed steps, checkbox status, and button status
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  // State variables for data from child component, total amount from child component, and accordion details
  const [dataFromChild, setDataFromChild] = useState('');
  const [totalAmountFromChild, setTotalAmountFromChild] = useState('');
  const [showAccordionDetails, setShowAccordionDetails] = useState(false);

  // State variables for form visibility, dialog visibility, and auto fill billing address
  const [showForm, setShowForm] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [autoFillBilling, setAutoFillBilling] = useState(true);

  // State variables for selected address index, addresses, Razorpay, ordered status, products, total price, payment ID, date, and visibility flags
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [Razorpay, isLoaded] = useRazorpay();
  const [ordered, setOrdered] = useState(null);
  const [products, setProducts] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [paymentId, setPaymentId] = useState(null);
  const [date, setDate] = useState(null)
  const [softImageVisible, setSoftImageVisible] = useState(true);
  const [placedOrderVisible, setPlacedOrderVisible] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [showAnimation, setShowAnimation] = useState(true);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [resentOtp, setResentOtp] = useState(false);
  const [showError, setShowError] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [showAddressForm, setshowAddressForm] = useState(false);
  const [pincodeForForm, setPincodeForForm] = useState('');
  const [searchClicked, setSearchClicked] = useState(false);
  const userId = useUserId();

  // Function to format a date string
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  //step1:mobile number
  // Function to handle login continue
  const handleLoginContinue = async () => {
    const payload = {
      phoneNumber: phoneNumber,
      receiveNotifications: isChecked
    };
    // console.log(payload);
    try {
      if (userId) {
        await fetchUserDetails();
      } else {
        await handlePhoneSubmit();
      }
      handleNext();
      handleComplete();
    } catch (error) {
      console.error('Error:', error);
      // Handle error
    }
  };
  // Function to handle OTP submission
  const onOtpSubmit = (otp) => {
    handleNext(); // Move to the next step
    handleComplete(); // Mark the current step as completed
  }

  // Function to fetch user details from the server
  const fetchUserDetails = async () => {
    try {
      const response = await fetch(baseURL + `profile/user-details/${userId}`, {
        method: 'GET',
      });

      const data = await response.json();
      if (response.ok) {
        // Update form data with user details fetched from the server
        setPhoneNumber(data.phoneNumber);
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
      throw error;
    }
  };
 
  // Function to handle phone number submission
  const handlePhoneSubmit = async (event) => {
    event.preventDefault();
    if (!phoneNumber || phoneNumber.length < 13) {
      setShowError(true); // Show error if phone number is empty
      return;
    }
    // Prepare the data to be sent in the POST request
    const requestData = {
      phoneNumber: phoneNumber // Assuming phoneNumber is defined elsewhere in your code
    };

    try {
      const response = await fetch(baseURL + 'profile/send-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // If the request was successful, you can handle the response here
      // For example, you can check if the OTP was sent successfully
      const responseData = await response.json();
      // console.log(responseData); // Handle the response data as per your API's response format
    } catch (error) {
      console.error('There was a problem with your fetch operation:', error);
      // Handle errors here, such as displaying an error message to the user
    }
    setShowOtpInput(true);
    setResentOtp(false);
  };

  // Function to handle resending OTP
  const handleResendOtp = (event) => {
    event.preventDefault();
    setResentOtp(true);
    handlePhoneSubmit(event); // Pass the event to handlePhoneSubmit
  };

  // Fetch user details when the userId changes
  useEffect(() => {
    if (userId !== null) {
      fetchUserDetails();
    }
  }, [userId]);

  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Function to handle key press events
  const handleKeyPress = (e) => {
    // Prevent typing alphabets
    if (e.key.match(/[a-zA-Z]/)) {
      e.preventDefault();
    }
    setShowError(false);
    if (e.key === 'Enter') {
      handlePhoneSubmit(e);
    }
  };

  // State variables for delivery address and billing address
  const [deliveryAddress, setDeliveryAddress] = useState({
    fullname: '',
    address: '',
    city: '',
    state: '',
    pincode: pincodeForForm,
    email: '',
    phoneNumber: ''
  });
  const [billingAddress, setBillingAddress] = useState({
    fullname: '',
    address: '',
    city: '',
    state: '',
    pincode: pincodeForForm,
    email: '',
    phoneNumber: ''
  });

  // Function to handle opening the dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Function to handle data received from child component
  const handleDataFromChild = (data) => {
    setDataFromChild(data);
    // console.log("products..... :", data);
  };

  // Function to handle the amount received from the child component
  const handleAmountFromChild = (data) => {
    setTotalAmountFromChild(data);
    // console.log("totalPrice :", data);
  };
  // Function to handle checkbox change
  const handleCheckChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
    setIsButtonEnabled(event.target.checked); // Enable/disable button based on checkbox
  };


  // Function to validate an email address
  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };


  // Function to handle changes in the delivery and billing address fields
  const handleChange = (type, eOrValue, field = null) => {
    let name, value;

    if (eOrValue && eOrValue.target) {
      // eOrValue is an event object
      name = eOrValue.target.name;
      value = eOrValue.target.value;
    } else {
      // eOrValue is a direct value (e.g., from PhoneInput)
      name = field;
      value = eOrValue;
    }

    if (type === 'delivery') {
      setDeliveryAddress(prev => ({ ...prev, [name]: value }));
    } else if (type === 'billing') {
      setBillingAddress(prev => ({ ...prev, [name]: value }));
    }
  };
  

  //validation
  const isFormValid = () => {
    // Check if all required fields are filled
    return (
      deliveryAddress.fullname &&
      deliveryAddress.address &&
      deliveryAddress.email &&
      validateEmail(deliveryAddress.email) &&
      deliveryAddress.city &&
      deliveryAddress.state &&
      deliveryAddress.pincode &&
      deliveryAddress.phoneNumber &&
      billingAddress.fullname &&
      billingAddress.address &&
      billingAddress.email &&
      validateEmail(billingAddress.email) &&
      billingAddress.city &&
      billingAddress.state &&
      billingAddress.pincode &&
      billingAddress.phoneNumber
    );
  };
  const [showBillingAddressForm, setShowBillingAddressForm] = useState(!autoFillBilling); // Initially show billing address form if autoFillBilling is false
  // useEffect to update the billing address when autoFillBilling is toggled
  useEffect(() => {
    if (autoFillBilling) {
      setBillingAddress({ ...deliveryAddress });
    }
  }, [autoFillBilling, deliveryAddress]);
  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setAutoFillBilling(!autoFillBilling);
    if (!autoFillBilling) {
      setBillingAddress({ ...deliveryAddress });
      setShowBillingAddressForm(false); // Hide billing address form when checkbox is checked
    } else {
      setBillingAddress({
        fullname: '',
        address: '',
        city: '',
        state: '',
        pincode: '',
        email: '',
        phoneNumber: ''
      });
      setShowBillingAddressForm(true); // Show billing address form when checkbox is unchecked
    }
  };
  // Function to handle address selection
  const handleAddressSelect = (e) => {
    // Get the selected address index from the event target value
    const index = parseInt(e.target.value, 10);
    // Set the selected address index in the state
    setSelectedAddressIndex(index);
  };
  //add new address 
  const handleAddButtonClick = () => {
    setShowForm(prevState => !prevState);
    setShowAccordionDetails(true);

  };
  //address post api
  // Function to handle delivery address submission
  const handleDeliverAddress = async () => {
    // Check if both deliveryAddress and billingAddress exist and are non-empty
    if (!deliveryAddress || !isAddressNotEmpty(deliveryAddress) || !billingAddress || !isAddressNotEmpty(billingAddress)) {
      // If any address is missing or empty, return without making the API call
      return;
    }

    // Construct payload with non-empty addresses
    const payload = {
      deliveryAddress: {
        fullname: deliveryAddress.fullname,
        address: deliveryAddress.address,
        city: deliveryAddress.city,
        state: deliveryAddress.state,
        pincode: deliveryAddress.pincode,
        email: deliveryAddress.email,
        phoneNumber: deliveryAddress.phoneNumber
      },
      billingAddress: {
        fullname: billingAddress.fullname,
        address: billingAddress.address,
        city: billingAddress.city,
        state: billingAddress.state,
        pincode: billingAddress.pincode,
        email: billingAddress.email,
        phoneNumber: billingAddress.phoneNumber
      }
    };

    // Make the API call to submit the addresses
    try {
      const response = await fetch(
        baseURL + `users/${userId}/addresses`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload)
        }
      );
      if (response.ok) {
        // The request was successful
      } else {
        console.error('Request failed with status:', response.status);
      }
    } catch (error) {
      console.error('Error submitting addresses:', error);
    }
  };

  // Helper function to check if an address object is not empty
  const isAddressNotEmpty = (address) => {
    return Object.values(address).some(value => value !== '');
  };
  // address get api in select options
  const fetchUserInfo = () => {
    fetch(baseURL + `users/${userId}/all-addresses`)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setAddresses(data.addresses);
        } else {
          console.error('Failed to fetch addresses:', data.error);
        }
      })
      .catch(error => {
        console.error('Error fetching addresses:', error);
      });
  };

  useEffect(() => {
    if (userId) {
      fetchUserInfo();
    }
  }, [userId]);


  // Function to generate a custom orderId
  function generateCustomOrderId() {
    return 'ORD-' + Math.floor(Math.random() * 1000000);
  }
  const orderId = generateCustomOrderId(); // Generate a custom orderId
  
  //place order post api and razorpay
  const handlePlaceOrderClick = useCallback(() => {
    if (!isButtonEnabled) return;
    const selectedPaymentMethod = document.querySelector('input[name="radio-buttons-group"]:checked').value;
    let payload = {};
    if (selectedAddressIndex !== null) {
      const address = addresses[selectedAddressIndex];
      payload = address;
    } else {
      payload = {
        deliveryAddress: {
          fullname: deliveryAddress.fullname,
          address: deliveryAddress.address,
          city: deliveryAddress.city,
          state: deliveryAddress.state,
          pincode: deliveryAddress.pincode,
          email: deliveryAddress.email,
          phoneNumber: deliveryAddress.phoneNumber
        },
        billingAddress: {
          fullname: billingAddress.fullname,
          address: billingAddress.address,
          city: billingAddress.city,
          state: billingAddress.state,
          pincode: billingAddress.pincode,
          email: billingAddress.email,
          phoneNumber: billingAddress.phoneNumber
        }
      };
    }
    let products = dataFromChild;
    var shippingOrderId;
    const orderDate = new Date().toISOString().slice(0, 10);
    const shiprocketPayload = {
      "order_id": orderId,
      "order_date": orderDate,
      "pickup_location": "Primary",
      "channel_id": orderId, // Assuming order ID can be used as channel ID
      "billing_customer_name": payload.billingAddress.fullname,
      "billing_last_name": payload.billingAddress.fullname,
      "billing_address": payload.billingAddress.address,
      "billing_address_2": payload.billingAddress.address,
      "billing_city": payload.billingAddress.city,
      "billing_pincode": payload.billingAddress.pincode,
      "billing_state": payload.billingAddress.state,
      "billing_country": "India",
      "billing_email": payload.billingAddress.email,
      "billing_phone": payload.billingAddress.phoneNumber, // Convert phoneNumber to string
      "shipping_is_billing": true,
      "order_items": products.map(product => ({
        "name": product.title,
        "sku": product.productId,
        "units": product.quantity,
        "selling_price": product.originalPrice,
        "discount": product.originalPrice - totalAmountFromChild, // You may need to fill this
        "tax": "", // You may need to fill this
        "hsn": 441122 // Assuming this is static
      })),
      "payment_method": selectedPaymentMethod,
      "shipping_charges": 0, // You may need to fill this
      "giftwrap_charges": 0, // You may need to fill this
      "transaction_charges": 0, // You may need to fill this
      "total_discount": 0, // You may need to fill this
      "sub_total": totalAmountFromChild,
      "length": 10, // You may need to fill this
      "breadth": 15, // You may need to fill this
      "height": 20, // You may need to fill this
      "weight": 2.5 // You may need to fill this
    };
    // Post data to the external API after order placement
    const createShiprocketOrder = (shiprocketPayload) => {
      return fetch('https://apiv2.shiprocket.in/v1/external/orders/create/adhoc', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjQ2NzYyODYsInNvdXJjZSI6InNyLWF1dGgtaW50IiwiZXhwIjoxNzE1NTkxNjY0LCJqdGkiOiJSdld1ZnVuc1hkUTR6RDA4IiwiaWF0IjoxNzE0NzI3NjY0LCJpc3MiOiJodHRwczovL3NyLWF1dGguc2hpcHJvY2tldC5pbi9hdXRob3JpemUvdXNlciIsIm5iZiI6MTcxNDcyNzY2NCwiY2lkIjo0NTIyMDM4LCJ0YyI6MzYwLCJ2ZXJib3NlIjpmYWxzZSwidmVuZG9yX2lkIjowLCJ2ZW5kb3JfY29kZSI6IiJ9.MdfymtFcSQ-4bgb0RzCu9fl0CwjoydRWwEqsbnMamsE'
        },
        body: JSON.stringify(shiprocketPayload),
      })
        .then(response => response.json())
        .then(data => {
          shippingOrderId = data.order_id;
        })
        .catch(error => {
          console.error('Error sending data to Shiprocket:', error);
          // Handle error if Shiprocket API call fails
        });
    }
    // Convert rupees to paise
    let amountInPaise = Math.round(totalAmountFromChild * 100); // Rounding to handle decimal precision
    //handleRazorpayPayment
    const handleRazorpayPayment = (payload) => {
      const options = {
        key: "rzp_test_2HFQ05fTHCF1Ml",
        amount: amountInPaise,
        currency: "INR",
        name: "Livon Comforts",
        description: "Test Transaction",
        image: companyLogo,
        prefill: {
          name: payload.billingAddress.fullname,
          email: payload.billingAddress.email,
          contact: payload.billingAddress.phoneNumber, // You might want to fetch this from somewhere
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#255852",
        },
        handler: (res) => {
          if (res.razorpay_payment_id) {
            setPaymentStatus('success');
          } else {
            setPaymentStatus('failure');
          }
          const paymentId = res.razorpay_payment_id;
          createShiprocketOrder(shiprocketPayload);
          placeOrder(paymentId, payload, orderId);
        },
      };
      const rzpay = new Razorpay(options);
      rzpay.open();
    };

    // Function to handle the order placement and post data to API
    const placeOrder = async (paymentId, payload, orderId) => {
      const orderData = {
      userId: userId,
      paymentId: paymentId,
      payload: payload,
      orderId: orderId,
      paymentMethod: selectedPaymentMethod,
      totalPrice: totalAmountFromChild,
      products: dataFromChild
      };
      try {
      const response = await fetch(`${baseURL}orders/place-order/${userId}`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
      });
      const data = await response.json();
      setOrdered(data);
      localStorage.removeItem('cartItemsCount');
      const additionalResponse = await fetch(`${baseURL}orders/getorders/${userId}/${orderId}`);
      const additionalData = await additionalResponse.json();
      setProducts(additionalData.products);
      setTotalPrice(additionalData.totalPrice);
      setPaymentId(additionalData.paymentId);
      setDate(additionalData.createdAt);
      setSoftImageVisible(false);
      setPaymentSuccess(true);
      setTimeout(() => {
        setShowAnimation(false);
        setPlacedOrderVisible(true);
      }, 3000);
      } catch (error) {
      console.error('Error placing order:', error);
      }
    };

    if (selectedPaymentMethod === "prepaid") {
      handleRazorpayPayment(payload);
    }
    else {
      handleComplete();
      handleClickOpen();
      setSoftImageVisible(false);
      setPlacedOrderVisible(true);
    }
  }, [isButtonEnabled, totalAmountFromChild, addresses, selectedAddressIndex, billingAddress]);


  //stepper steps
  const totalSteps = () => {
    return steps.length;
  };
  // Function to calculate the number of completed steps
  const completedSteps = () => {
    return Object.keys(completed).length;
  };
  // Function to check if the current step is the last step
  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };
  // Function to check if all steps are completed
  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  // Function to handle the next step
  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };
  // Function to handle a specific step
  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  // Function to handle completing a step
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
    setPaymentSuccess(true);
  };

  // Handle pincode change event
  const handlePincodeChange = useCallback((e) => {
    const { value } = e.target;
    const newValue = value.replace(/\D/g, '').slice(0, 6);
    setInputValue(newValue);
    if (newValue.trim() !== '') {
      fetch(baseURL+`zipcode/`)
        .then((response) => response.json())
        .then((data) => {
          const matchedSuggestions = data.reduce((acc, item) => {
            item.zipcode.forEach((zip) => {
              if (zip.startsWith(newValue)) {
                acc.push(zip);
              }
            });
            return acc;
          }, []);
          if (newValue.length === 6) {
            // Show the form only if input is exactly 6 digits
            if (matchedSuggestions.length === 0) {
              setErrorMessage(
                'We are not currently delivering to this location. Please select another location.'
              );
              setshowAddressForm(false); // Hide the form if no suggestions found for 6-digit zipcode
            } else {
              setErrorMessage('');
              setshowAddressForm(true); // Show the form if suggestions found for 6-digit zipcode
            }
          } else {
            setErrorMessage('');
            setshowAddressForm(false); // Hide the form if input length is less than 6 digits
          }
          setSuggestions(matchedSuggestions);
        })
        .catch((error) => console.error('Error fetching data:', error));
    } else {
      setSuggestions([]);
      setErrorMessage('');
      setshowAddressForm(false); // Hide the form if input is empty
    }
  }, [baseURL, setInputValue, setErrorMessage, setshowAddressForm, setSuggestions]);

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    handleSearchClick(); // Call handleSearchClick function
    setInputValue(suggestion); // Set the input value to the suggestion
    setPincodeForForm(suggestion); // Set the pin code for form input
    setshowAddressForm(true); // Show the form
    setDeliveryAddress(prevAddress => ({ ...prevAddress, pincode: suggestion })); // Update deliveryAddress pincode
    setSuggestions([]); // Clear the suggestions array
  };

  // Handle search button click
  const handleSearchClick = () => {
    setSearchClicked(true); // Set search clicked to true
    setPincodeForForm(inputValue); // Set the pin code for form input
    setDeliveryAddress(prevAddress => ({ ...prevAddress, pincode: inputValue }));
  };


  return (
    <>
      <div>
        {softImageVisible && (
          <div className="soft container-fluid form" id="softimage">
            <div className=" container-fluid mt-2">
              <div className="row">
                <div className="col-md-6 login">
                  <div className="row my-4">
                    <div id="form" className="w-100">
                      <Box sx={{ width: '100%' }}>
                        <Stepper activeStep={activeStep} alternativeLabel className='mb-5 custom-stepper'>
                          {steps.map((label, index) => (
                            <Step key={label} completed={completed[index]}>
                              <StepButton onClick={handleStep(index)} className='steper-completed'>
                                <Typography variant="body3" fontWeight="bold" className="custom-step-button">
                                  {label}
                                </Typography>
                              </StepButton>
                            </Step>
                          ))}
                        </Stepper>
                        <div>
                          {allStepsCompleted() ? (
                            <div>
                              <Typography sx={{ mt: 2, mb: 1 }}>
                                {/* All steps completed - you&apos;re finished */}
                              </Typography>
                              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                {/* <Button onClick={handleReset}>Reset</Button> */}
                              </Box>
                            </div>
                          ) : (
                            <div>
                              <Accordion expanded={activeStep === 0} >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} className='accordion-bg'>
                                  <Typography className="bold-text">SIGN IN or SIGN UP</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                      <div className="accordion-body">
                                        {userId ? (
                                          <div className='text-center'>
                                            <FontAwesomeIcon icon={faCircleCheck} className='number-checktick' size='2xl' style={{ color: "#255852", }} />
                                            <p className="mt-2 mx-3 checkout_message text-center" style={{ fontSize: '1rem' }}> You've Logged In Successfully</p>
                                            <div className="mx-3 mt-3">
                                              <p className='logged-phonenumber'>{phoneNumber}</p>
                                            </div>
                                            <div className="text-center my-2">
                                              <button
                                                onClick={handleLoginContinue}
                                                onKeyPress={(event) => {
                                                  if (event.key === 'Enter') {
                                                    handleLoginContinue();
                                                  }
                                                }}
                                                id="otp-pomsd"
                                                className="btn btn-outline-dark">
                                                CONTINUE
                                              </button>

                                            </div>
                                          </div>
                                        ) : (
                                          <div className="form-data">
                                            {!showOtpInput ? (<form onSubmit={handlePhoneSubmit}>
                                              <p className="text-center otp my-4">Enter your phone number and we will send you a 6 digit OTP.</p>
                                              <PhoneInput
                                                international
                                                defaultCountry="IN"
                                                value={phoneNumber}
                                                placeholder="Enter Phone Number"
                                                className="phonenumber-check"
                                                onChange={setPhoneNumber}
                                                onKeyPress={handleKeyPress}
                                                autoFocus
                                                maxLength='15' />
                                              {showError && <p className="error-message">Phone number is required.</p>} {/* Show error message */}
                                              <div className="mobileotp">
                                                <button className="btn btn-outline-success fw-bold">SEND OTP</button>
                                              </div>
                                            </form>) :
                                              (<div><p className="text-center otp my-4">We have sent a 6 digit OTP to {phoneNumber}. Please enter it here. <span className="resendotp" onClick={handleResendOtp}>Resend OTP?</span></p>
                                                <OtpInput phoneNumber={phoneNumber} length={6} onOtpSubmit={onOtpSubmit} resentOtp={resentOtp} />
                                              </div>)
                                            }

                                          </div>
                                        )}
                                      </div>

                                    </div>
                                  </Typography>

                                </AccordionDetails>
                              </Accordion>
                              <Accordion expanded={activeStep === 1} >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} className='accordion-bg'>
                                  <Typography className="bold-text">ENTER DETAILS</Typography>
                                </AccordionSummary>
                                <div className='errorgap'>
                                  <div className="form-data input-container">   
                                  <div className='mb-2  zipcodelabel'>
                                  <label >Enter your pincode</label>    
                                    </div>                    
                                    <input
                                      type="text"
                                      value={inputValue}
                                      onChange={handlePincodeChange}
                                      placeholder="Enter a zip code"
                                      pattern="[0-9]{6}"
                                      title="Please enter a 6-digit zip code"
                                      list="suggestionsList"
                                      className="Zipcode"
                                      style={{ borderColor: errorMessage ? 'red' : '' }}
                                      onKeyPress={(event) => {
                                        if (event.key === 'Enter') {
                                          handleSearchClick();
                                        }
                                      }}
                                    />
                                    <button onClick={handleSearchClick} 
                                   className="search-icon"><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
                                    {inputValue && suggestions.length > 0 && (
                                      <div className="suggestion-dropdown phonenumber-check">
                                        <ul>
                                          {suggestions.map((zip, index) => (
                                            <li key={index} onClick={() => handleSuggestionClick(zip)}>
                                              {zip}
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}

                                  </div>
                                  {errorMessage && <p style={{ color: 'red' }} className='text-center mt-2'>{errorMessage}</p>}
                                </div>
                                {showAddressForm && inputValue.length === 6 && searchClicked && (
                                  <div>
                                    <div>
                                      {addresses.length > 0 && (
                                        <p onClick={handleAddButtonClick} className='add-new'> {showForm ? 'Add new address +' : 'select from existing Addresses'}</p>
                                      )}
                                    </div>

                                    {addresses.length > 0 && showForm ? (
                                      <Form className='form_address'>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                          <FormControl as="select" onChange={handleAddressSelect} className="custom-select" defaultValue="">
                                            <option disabled value="">Select Delivery Address</option>
                                            {addresses.map((address, index) => (
                                              <option key={index} value={index} className='w-100'>
                                                {address.deliveryAddress ? (
                                                  `${address.deliveryAddress.fullname}, ${address.deliveryAddress.address}, ${address.deliveryAddress.city}, ${address.deliveryAddress.state}, ${address.deliveryAddress.pincode}, ${address.deliveryAddress.phoneNumber}, ${address.deliveryAddress.email}`
                                                ) : (
                                                  `${address.address}, ${address.fullname}, ${address.city}, ${address.state}, ${address.pincode}, ${address.phoneNumber}`
                                                )}
                                              </option>
                                            ))}
                                          </FormControl>
                                        </Form.Group>
                                        <div className="text-center my-2">
                                          <button
                                            type="button" // Change the type to "button"
                                            id="otp-pomsd"
                                            className="rounde btn btn-outline-dark mt-3"
                                            onClick={() => {
                                              if (selectedAddressIndex !== null && selectedAddressIndex !== '') {
                                                handleNext();
                                                handleComplete();
                                                handleDeliverAddress();
                                              } else {

                                                alert("Please select a delivery address.");
                                              }
                                            }}
                                            onKeyPress={(event) => {
                                              if (event.key === 'Enter') {
                                                handleLoginContinue();
                                              }
                                            }}
                                          >
                                            CONTINUE
                                          </button>
                                          <br />
                                        </div>
                                      </Form>

                                    ) : (
                                      <div>
                                        <AccordionDetails>
                                          <Typography>
                                            {isAddressError && <Alert key={'danger'} variant={'danger'} onClose={() => setIsAddressError(false)} dismissible>
                                              All input fields are required.
                                            </Alert>}
                                            <Form >
                                              <Row className="mb-3 address-form">
                                                <h3 className="title fs-5 mb-2">Delivery Address</h3>
                                                <Form.Group as={Col} md="4" lg="6" controlId="validationCustom01">
                                                  <Form.Label>Full name</Form.Label>
                                                  <Form.Control
                                                    type="text"
                                                    name="fullname"
                                                    value={deliveryAddress.fullname}
                                                    onChange={(e) => handleChange('delivery', e)}
                                                    placeholder="Full name"
                                                    className='input'
                                                    required
                                                  />
                                                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" lg="6" controlId="validationCustom03">
                                                  <Form.Label>Email</Form.Label>
                                                  <InputGroup hasValidation>
                                                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                                    <Form.Control
                                                      type="text"
                                                      name="email"
                                                      value={deliveryAddress.email}
                                                      onChange={(e) => handleChange('delivery', e)}
                                                      placeholder="Enter Email"
                                                      className='input'
                                                      required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                      {deliveryAddress.email && !validateEmail(deliveryAddress.email) ? "Email must contain @" : "Please fill email."}
                                                    </Form.Control.Feedback>
                                                  </InputGroup>
                                                </Form.Group>
                                                <Form.Group as={Col} md="12" lg="12" controlId="validationCustom02">
                                                  <Form.Label className='mt-2'>Address</Form.Label>
                                                  <Form.Control
                                                    as="textarea"
                                                    name="address"
                                                    value={deliveryAddress.address}
                                                    onChange={(e) => handleChange('delivery', e)}
                                                    className='input'
                                                    required
                                                  />
                                                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md="12" lg="12" controlId="validationCustom04" className='delivery-details'>
                                                  <Form.Label className="mt-2">City</Form.Label>
                                                  <Form.Control
                                                    type="text"
                                                    name="city"
                                                    value={deliveryAddress.city}
                                                    onChange={(e) => handleChange('delivery', e)}
                                                    placeholder="Enter City"
                                                    className='input'
                                                    required
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    Please provide a valid city.
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" lg="6" controlId="validationCustom05" className='delivery-details'>
                                                  <Form.Label className="mt-2">State</Form.Label>
                                                  <Form.Select
                                                    type="text"
                                                    name="state"
                                                    value={deliveryAddress.state}
                                                    onChange={(e) => handleChange('delivery', e)}
                                                    placeholder="Enter State"
                                                    required
                                                    aria-label="Default select example" className='input'>
                                                    <option value="" disabled>Select State</option>
                                                    <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                    <option value="Assam">Assam</option>
                                                    <option value="Bihar">Bihar</option>
                                                    <option value="Chandigarh">Chandigarh</option>
                                                    <option value="Chhattisgarh">Chhattisgarh</option>
                                                    <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                                                    <option value="Daman and Diu">Daman and Diu</option>
                                                    <option value="Delhi">Delhi</option>
                                                    <option value="Goa">Goa</option>
                                                    <option value="Gujarat">Gujarat</option>
                                                    <option value="Haryana">Haryana</option>
                                                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                                    <option value="Jharkhand">Jharkhand</option>
                                                    <option value="Karnataka">Karnataka</option>
                                                    <option value="Kerala">Kerala</option>
                                                    <option value="Lakshadweep">Lakshadweep</option>
                                                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                    <option value="Maharashtra">Maharashtra</option>
                                                    <option value="Manipur">Manipur</option>
                                                    <option value="Meghalaya">Meghalaya</option>
                                                    <option value="Mizoram">Mizoram</option>
                                                    <option value="Nagaland">Nagaland</option>
                                                    <option value="Odisha">Odisha</option>
                                                    <option value="Puducherry">Puducherry</option>
                                                    <option value="Punjab">Punjab</option>
                                                    <option value="Rajasthan">Rajasthan</option>
                                                    <option value="Sikkim">Sikkim</option>
                                                    <option value="Tamil Nadu">Tamil Nadu</option>
                                                    <option value="Telangana">Telangana</option>
                                                    <option value="Tripura">Tripura</option>
                                                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                    <option value="Uttarakhand">Uttarakhand</option>
                                                    <option value="West Bengal">West Bengal</option>
                                                  </Form.Select>
                                                  <Form.Control.Feedback type="invalid">
                                                    Please provide a valid state.
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" lg="6" controlId="validationCustom06" className='delivery-details'>
                                                  <Form.Label className="mt-2">Pincode</Form.Label>
                                                  <Form.Control
                                                    type="text"
                                                    name="pincode"
                                                    value={deliveryAddress.pincode}
                                                    onChange={(e) => handleChange('delivery', e)}
                                                    className='input'
                                                    require
                                                    placeholder="Enter Zip"
                                                    readOnly
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    Please provide a valid zip.
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" lg="6" controlId="validationCustom06" className='delivery-details'>
                                                  <Form.Label className="mt-2">phone Number</Form.Label>
                                                  <PhoneInput
                                                    international
                                                    defaultCountry="IN"
                                                    name="phoneNumber"
                                                    value={deliveryAddress.phoneNumber}
                                                    onChange={(value) => handleChange('delivery', value, 'phoneNumber')}
                                                    className="input-phone"
                                                    placeholder="Enter phoneNumber"
                                                    maxLength='15'
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    Please provide a valid phone Number.
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="my-3">
                                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Form.Check
                                                      name="sameAsBilling"
                                                      label=""
                                                      checked={autoFillBilling}
                                                      onChange={handleCheckboxChange}
                                                      id="sameAsBillingCheckbox"
                                                      style={{ marginRight: '8px' }}
                                                      required
                                                    />
                                                    <Form.Label htmlFor="sameAsBillingCheckbox" style={{ margin: 0, fontWeight: 'bold' }}>
                                                      Billing address same as delivery address
                                                    </Form.Label>
                                                  </div>
                                                  <Form.Control.Feedback type="invalid">
                                                    You must agree before submitting.
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                              </Row>
                                              {showBillingAddressForm && (
                                                <Row className="mb-3">
                                                  <h3 className="title fs-5 mb-2">Billing Address</h3>
                                                  <Form.Group as={Col} md="4" lg="6" controlId="validationCustom01">
                                                    <Form.Label>Full name</Form.Label>
                                                    <Form.Control
                                                      type="text"
                                                      name="fullname"
                                                      value={billingAddress.fullname}
                                                      onChange={(e) => handleChange('billing', e)}
                                                      placeholder="Full name"
                                                      className='input'
                                                      required
                                                    />
                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                  </Form.Group>

                                                  <Form.Group as={Col} md="4" lg="6" controlId="validationCustom03">
                                                    <Form.Label >Email</Form.Label>
                                                    <InputGroup hasValidation>
                                                      <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                                      <Form.Control
                                                        type="email"
                                                        name="email"
                                                        value={billingAddress.email}
                                                        onChange={(e) => handleChange('billing', e)}
                                                        placeholder="Enter Email"
                                                        className='input'
                                                        required
                                                      />
                                                      <Form.Control.Feedback type="invalid">
                                                        {billingAddress.email && !validateEmail(billingAddress.email) ? "Email must contain @" : "Please fill email."}
                                                      </Form.Control.Feedback>
                                                    </InputGroup>
                                                  </Form.Group>
                                                  <Form.Group as={Col} md="12" lg="12" controlId="validationCustom02">
                                                    <Form.Label className='mt-2'>Address</Form.Label>
                                                    <Form.Control
                                                      as="textarea"
                                                      name="address"
                                                      value={billingAddress.address}
                                                      onChange={(e) => handleChange('billing', e)}
                                                      placeholder="Enter Address"
                                                      className='input'
                                                      required
                                                    />
                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                  </Form.Group>
                                                  <Form.Group as={Col} md="12" lg="12" controlId="validationCustom04" className='delivery-details'>
                                                    <Form.Label className="mt-2">City</Form.Label>
                                                    <Form.Control
                                                      type="text"
                                                      name="city"
                                                      value={billingAddress.city}
                                                      onChange={(e) => handleChange('billing', e)}
                                                      placeholder="Enter City"
                                                      className='input Delivery-form'
                                                      required />
                                                    <Form.Control.Feedback type="invalid">
                                                      Please provide a valid city.
                                                    </Form.Control.Feedback>
                                                  </Form.Group>
                                                  <Form.Group as={Col} md="4" lg="6" controlId="validationCustom05" className='delivery-details'>
                                                    <Form.Label className="mt-2">State</Form.Label>
                                                    <Form.Select
                                                      type="text"
                                                      name='state'
                                                      value={billingAddress.state}
                                                      onChange={(e) => handleChange('billing', e)}
                                                      placeholder="Enter State"
                                                      required
                                                      aria-label="Default select example" className='input'>
                                                      <option value="" disabled>Select State</option>
                                                      <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                                      <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                      <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                      <option value="Assam">Assam</option>
                                                      <option value="Bihar">Bihar</option>
                                                      <option value="Chandigarh">Chandigarh</option>
                                                      <option value="Chhattisgarh">Chhattisgarh</option>
                                                      <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                                                      <option value="Daman and Diu">Daman and Diu</option>
                                                      <option value="Delhi">Delhi</option>
                                                      <option value="Goa">Goa</option>
                                                      <option value="Gujarat">Gujarat</option>
                                                      <option value="Haryana">Haryana</option>
                                                      <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                      <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                                      <option value="Jharkhand">Jharkhand</option>
                                                      <option value="Karnataka">Karnataka</option>
                                                      <option value="Kerala">Kerala</option>
                                                      <option value="Lakshadweep">Lakshadweep</option>
                                                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                      <option value="Maharashtra">Maharashtra</option>
                                                      <option value="Manipur">Manipur</option>
                                                      <option value="Meghalaya">Meghalaya</option>
                                                      <option value="Mizoram">Mizoram</option>
                                                      <option value="Nagaland">Nagaland</option>
                                                      <option value="Odisha">Odisha</option>
                                                      <option value="Puducherry">Puducherry</option>
                                                      <option value="Punjab">Punjab</option>
                                                      <option value="Rajasthan">Rajasthan</option>
                                                      <option value="Sikkim">Sikkim</option>
                                                      <option value="Tamil Nadu">Tamil Nadu</option>
                                                      <option value="Telangana">Telangana</option>
                                                      <option value="Tripura">Tripura</option>
                                                      <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                      <option value="Uttarakhand">Uttarakhand</option>
                                                      <option value="West Bengal">West Bengal</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                      Please provide a valid state.
                                                    </Form.Control.Feedback>
                                                  </Form.Group>
                                                  <Form.Group as={Col} md="4" lg="6" controlId="validationCustom06" className='delivery-details'>
                                                    <Form.Label className="mt-2">Pincode</Form.Label>
                                                    <Form.Control
                                                      type="text"
                                                      name="pincode"
                                                      value={billingAddress.pincode}
                                                      onChange={(e) => handleChange('billing', e)}
                                                      className={`input ${billingEmailError ? 'is-invalid' : ''}`}
                                                      placeholder="Enter Zip"
                                                      readOnly />
                                                    <Form.Control.Feedback type="invalid">
                                                      Please provide a valid zip.
                                                    </Form.Control.Feedback>
                                                  </Form.Group>
                                                  <Form.Group as={Col} md="4" lg="6" controlId="validationCustom06" className='delivery-details'>
                                                    <Form.Label className="mt-2">phone Number</Form.Label>
                                                    <Form.Control
                                                      type="text"
                                                      name="phoneNumber"
                                                      value={billingAddress.phoneNumber}
                                                      onChange={(e) => handleChange('billing', e)}
                                                      className='input'
                                                      require
                                                      placeholder="Enter phoneNumber"
                                                      required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                      Please provide a valid phone Number.
                                                    </Form.Control.Feedback>
                                                  </Form.Group>
                                                </Row>
                                              )}

                                              <div className="text-center my-2">
                                                <button
                                                  type="button" // Change the type to "button"
                                                  id="otp-pomsd"
                                                  className="rounde btn btn-outline-dark mt-3"
                                                  onClick={() => {
                                                    if (isFormValid()) {
                                                      handleNext();
                                                      handleComplete();
                                                      handleDeliverAddress();
                                                    } else {
                                                      setIsAddressError(true);
                                                    }
                                                  }}
                                                  onKeyPress={(event) => {
                                                    if (event.key === 'Enter') {
                                                      handleLoginContinue();
                                                    }
                                                  }}
                                                >
                                                  CONTINUE
                                                </button>

                                                <br />
                                              </div>
                                            </Form>
                                          </Typography>
                                        </AccordionDetails>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </Accordion>
                              <Accordion expanded={activeStep === 2} >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} className='accordion-bg'>
                                  <Typography className="bold-text">ORDER NOTES and PAYMENT</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <div className='orders_payment'>
                                      <textarea placeholder='Type your order notes here....' cols={65} rows={4} className='ordernote'></textarea>
                                    </div>
                                    <div className='order_check'>
                                      <input type='checkbox' onChange={handleCheckChange} id="TermsCheckbox" />
                                      <label className='terms_checkbox' style={{ marginLeft: "10px" }} htmlFor='TermsCheckbox'>I read and agree to the website <span className='Terms'>Terms and Conditions</span></label>
                                    </div>
                                    <div>
                                      <FormControl>
                                        <FormLabel id="demo-radio-buttons-group-label">please select your preferred payment method</FormLabel>
                                        <RadioGroup
                                          aria-labelledby="demo-radio-buttons-group-label"
                                          defaultValue="intrest"
                                          name="radio-buttons-group"
                                        >
                                          <div>
                                            <FormControlLabel value="intrest" control={<Radio />} label="Buy Now pay later with" /><img src='https://snapmint.com/assets/images/logo_v3.svg' width={100} alt='emi'/>
                                            <p className='emi'>pay or now. Rest in 0% interest EMIs</p>
                                          </div>
                                          <FormControlLabel value="prepaid" control={<Radio />} label="Cards,NetBanking,UPI,Wallets and EMI" />
                                        </RadioGroup>
                                      </FormControl>
                                    </div>
                                    <div className='place-order mt-4'>
                                      <button className={isButtonEnabled ? 'enabled' : 'disabled'} onClick={() => {
                                        handlePlaceOrderClick();
                                      }} disabled={!isButtonEnabled}>Place Order</button>
                                    </div>
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          )}
                        </div>
                      </Box>
                    </div>
                  </div>
                </div>
                <Orderdetails sendDataToParent={handleDataFromChild} sendTotalAmountToParent={handleAmountFromChild} />
              </div>
            </div>
          </div>
        )}
      {paymentSuccess && showAnimation && <PaymentSuccessAnimation />}
      {placedOrderVisible && (
        <div className='space' id="placed-order">
          <div className='container'>
            <div className='center'>
              <FontAwesomeIcon className="orderPlacedIcon" icon={faCircleCheck} size='2xl' style={{ color: "#63E6BE" }} />
              <h2 className='Placed'>Your order has been placed successfully</h2>
            </div>
            {ordered && (
              <div className="place-order">
                <div className='row mt-5 details'>
                  <div className='col-lg-3'>
                    <p>Order Date</p>
                    <p className='order-det'>{formatDate(date)}</p>
                  </div>
                  <div className='col-lg-3'>
                    <p>Order Number</p>
                    <p className="order-det">{ordered.orderId}</p>
                  </div>
                  <div className='col-lg-3'>
                    <p>Payment Method</p>
                    <p className="order-det" style={{ textTransform: 'capitalize' }}>{ordered.paymentMethod}</p>
                  </div>
                  <div className='col-lg-3'>
                    <p>User</p>
                    <p className="order-det">{ordered.deliveryAddress.fullname}</p>
                  </div>
                </div>
              </div>
            )}
            {products && products.map((order, index) => (
              <div className='container order-data mb-3 mt-5' style={{ padding: '20px 32px', marginLeft: '-11px' }} key={index}>
                <div className='row'>
                  <div className="order-products">
                    <div className='row'>
                      <div className='col-md-4 cart-icon'>
                        <img src={order.imageurl[0]} alt="product-order" className="thumbnailImg" />
                      </div>
                      <div className='col-md-8 title ml-5'>
                        <p className='title-p'>{order.title}</p>
                        <div className="heading dimensions">
                          <div className='cart-sizes1'>
                            <p className='line'><b>Size:</b> {order.selectedCategoryType}</p>
                            {order.selectedHeight && (
                              <p className='line'><b>Height:</b> {order.selectedHeight} inches</p>
                            )}
                            {order.selectedCover && (
                              <p className='line'><b>Cover:</b> {order.selectedCover}</p>
                            )}
                            <p><b>Dimensions:</b> {order.selectedDimension}</p>
                          </div>
                        </div>
                        <p><b>Qty:</b> {order.quantity}</p>
                        <p><b>Price:</b> ₹ {order.originalPrice} /-</p>
                        <p><b>Total Price:</b> ₹ {order.originalPrice * order.quantity} /-</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <hr />
            <h6>Total Amount: <b>₹ {totalAmountFromChild.toFixed(2)}/-</b></h6>
          </div>
          <div className='d-flex cont-shopp mt-5 mb-5'>
            <p className="continue-shopping mr-3"><a href='/'>Back To Shopping</a></p>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1.5em" className="svg-icon" height="1.5em">
              <path d="M506.134 241.843l-.018-.019-104.504-104c-7.829-7.791-20.492-7.762-28.285.068-7.792 7.829-7.762 20.492.067 28.284L443.558 236H20c-11.046 0-20 8.954-20 20s8.954 20 20 20h423.557l-70.162 69.824c-7.829 7.792-7.859 20.455-.067 28.284 7.793 7.831 20.457 7.858 28.285.068l104.504-104 .018-.019c7.833-7.818 7.808-20.522-.001-28.314z">
              </path>
            </svg>
          </div>
        </div>
      )}

        <div className="container">
          <div className="row my-3">
            <div className="text-center my-5">
              <img src="https://cdn-icons-png.flaticon.com/512/7589/7589521.png" width="170px" alt="customer-care"/>
              <h3 className="my-3 text-success fw-bold">BUY LATEX MATTRESS ONLINE</h3>
              <h6>Talk to real humans, not robots.</h6>
              <div className="my-4 d-flex callus">
                <a href="tel:+919458419999"><button className="rounded callus-1 p-2 border">CALL US</button></a>
                <a href="mailto:sales@livoncomforts.com"> <button className="callus-2 rounded mx-2 p-2 border-0">EMAIL US</button></a>
                <a href="tel:+919458419999"><button className="callus-3 rounded p-2 border-0">LIVE CHAT</button></a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default Checkout;