import React, { useState, useEffect, useContext } from "react";
import './Header.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import companyLogo from './../images/livon comfort logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCartShopping, faPhone, faHand } from '@fortawesome/free-solid-svg-icons';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import OtpInput from "./OtpInput";
import Addtocart from "./Addtocart";
import { Link } from "react-router-dom";
import baseURL from "../Environment";
import useUserId from './UseUserId'; // Import the custom hook
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'; // Make sure to import the styles
import { CartContext } from './CartContext';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
function Header() {
  // State variables for managing the component's behavior
  const [open, setOpen] = useState(false); // State for managing the dialog open/close
  const [phoneNumber, setPhoneNumber] = useState(""); // State for storing the phone number input
  const [showOtpInput, setShowOtpInput] = useState(false); // State for showing/hiding the OTP input
  const [resentOtp, setResentOtp] = useState(false); // State for tracking if OTP has been resent
  const [sideBarshow, setSidebarShow] = useState(false); // State for showing/hiding the sidebar
  const [showDropdown, setShowDropdown] = useState(false); // State for showing/hiding the dropdown
  const [dropdownCategory, setDropdownCategory] = useState(""); // State for storing the selected dropdown category
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false); // State for tracking if the user is logged in
  const [categories, setCategories] = useState([]); // State for storing the categories
  const [products, setProducts] = useState([]); // State for storing the products
  const [showError, setShowError] = useState(false); // State for showing/hiding the error message
  const [navbarExpanded, setNavbarExpanded] = useState(false); // State for controlling the Navbar collapse
  const [hoveredProductId, setHoveredProductId] = useState(null); // State for tracking the hovered product ID
  const [hoveredProductImage, setHoveredProductImage] = useState(null); // State for tracking the hovered product image
  const navigate = useNavigate();
  // Retrieves the count of cart items from the CartContext.
  const { cartItemsCount } = useContext(CartContext);

  // Function to handle the call action
  const handleCall = () => {
    window.location.href = 'tel: +7942546624'; // Redirects the user to the phone call interface with the specified phone number
  };

  // Get the user ID using the custom hook
  const userId = useUserId();

  // Function to handle logout
  const handleLogout = async () => {
    try {
      await axios.post(baseURL + `profile/logout/${userId}`, {
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers if needed
        },
      });
  // Handle successful logout (e.g., clear local storage, redirect, etc.)
      localStorage.removeItem('token');
      navigate('/'); // Navigate to the home page without a full page reload
    } catch (error) {
      // Handle error
      console.error('Logout failed', error);
    }
  };



  // Function to handle closing the login dialog
  const handleLoginClose = () => {
    setShowOtpInput(false); // Resetting to initial step
    setPhoneNumber(''); // Clear phone number input
    setOpen(false); // Close the dialog
    setShowError(false); // Reset error state
  };



// Function to handle user icon click
const handleLoginOpen = () => {
  if (isUserLoggedIn || userId) {
    navigate('/account-detail'); // Navigate to '/account-detail' without page refresh
  } else {
    // If not logged in, show the login dialog
    setOpen(true);
  }
};



  // Function to handle key press events
  const handleKeyPress = (e) => {
    // Prevent typing alphabets
    if (e.key.match(/[a-zA-Z]/)) {
      e.preventDefault();
    }
    setShowError(false);
    if (e.key === 'Enter') {
      handlePhoneSubmit(e);
    }
  };



  // Function to handle phone number submission
  const handlePhoneSubmit = async (event) => {
    event.preventDefault();
    if (!phoneNumber || phoneNumber.length < 13) {
      setShowError(true); // Show error if phone number is empty
      return;
    }
    try {
      const requestData = {
        phoneNumber: phoneNumber
      };
      const response = await axios.post(baseURL + 'profile/send-otp', requestData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      // Handle the response data as per your API's response format
    } catch (error) {
      console.error('There was a problem with your axios request:', error);
      // Handle errors here, such as displaying an error message to the user
    }
    setResentOtp(false);
    setShowOtpInput(true);
  };


  // Function to handle resending OTP
  const handleResendOtp = (event) => {
    event.preventDefault();
    setResentOtp(true);
    handlePhoneSubmit(event); // Pass the event to handlePhoneSubmit
  };


  // Function to handle OTP submission
  const onOtpSubmit = (otp) => {
    setIsUserLoggedIn(true);
  }


  // Function to handle adding item to cart in the side navigation
  const handleAddToCartSideNav = () => {
    setSidebarShow(!sideBarshow);
  }

  // Function to handle closing the side navigation
  const handleCloseSideNav = (data) => {
    setSidebarShow(data);
  }

  // Function to fetch categories and products
  const fetchCategoriesAndProducts = async () => {
    try {
      const response = await axios.get(baseURL + `productdetails`);
      const data = response.data;
      // Check if data is available
      if (data.length > 0 && data[0].products) {
        // Extract unique categories from the products
        const uniqueCategories = [...new Set(data[0].products.map(product => product.category))];
        setCategories(uniqueCategories);
        setProducts(data[0].products);
      }
    } catch (error) {
      console.error("Error fetching categories: ", error);
    }
  };

  // Fetch categories and products on component mount
  useEffect(() => {
    fetchCategoriesAndProducts();
  }, []);

  // Set the default hovered product ID and image
  useEffect(() => {
    if (products.length > 0) {
      setHoveredProductId(products[0]._id);
      setHoveredProductImage(products[0].imageurl[0]);
    }
  }, [products]);

  // Function to handle mouse enter event for product
  const handleMouseEnter = (productId, imageUrl) => {
    setHoveredProductId(productId);
    setHoveredProductImage(imageUrl);
  };

  // Define category variable
  const category = 'Why Livon';

  // Return the JSX for the Header component
  return (
    <>
      <Navbar expand="lg" className="bg navbar-latex" fixed="top">
        <Container fluid>
          <Navbar.Brand as={Link} to="/"><img src={companyLogo} alt="BigCo Inc. logo" className="Logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbarlatex-toggle" onClick={() => setNavbarExpanded(!navbarExpanded)} />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto livon_navitems my-2 my-lg-0" style={{ maxHeight: '280px' }} navbarScroll>
              <Nav.Link as={Link} to="/" className="nav-item nav-homepage" onClick={() => setNavbarExpanded(false)}>Home</Nav.Link>
              {categories
                .map(category => (
                  <NavDropdown
                    title={category}
                    key={category}
                    id={`navbarScrollingDropdown-${category}`}
                    onMouseEnter={() => { setDropdownCategory(category); setShowDropdown(true); }}
                    onMouseLeave={() => setShowDropdown(false)}
                    show={showDropdown && dropdownCategory === category}

                  >
                    <div className="row mattress-dropdown">
                      <div className="col-lg-6 d-flex flex-column">
                        {products
                          .filter(product => product.category === category)
                          .map((filteredProduct, index) => (
                            <NavDropdown.Item
                              key={filteredProduct._id}
                              as={Link}
                              to={`/product/${category}/${filteredProduct._id}/${filteredProduct.title.replace(/\s+/g, '-')}`}
                              className={`title dropdown_item-${index + 1}`}
                              onMouseEnter={() => handleMouseEnter(filteredProduct._id, filteredProduct.imageurl[0])}
                            >
                              {filteredProduct.title}
                            </NavDropdown.Item>
                          ))
                        }
                      </div>
                      <div className="col-lg-6">
                        <img
                          src={hoveredProductImage}
                          alt="Product"
                          className="product-image image-remove"
                        />
                      </div>
                    </div>
                  </NavDropdown>
                ))
              }
              <NavDropdown
                style={{ margin: '0px 5px' }}
                title="Why Livon"
                onMouseEnter={() => { setDropdownCategory(category); setShowDropdown(true); }}
                onMouseLeave={() => setShowDropdown(false)}
                show={showDropdown && dropdownCategory === category}
              >
                <div className="row why-dropdown">
                  <div className="col-lg-6">
                    <NavDropdown.Item
                    as={Link}
                      to="/certifications"
                      className="nav-item nav-homepage dropdown_item-1"
                    >
                      Certifications
                    </NavDropdown.Item>
                    <NavDropdown.Item
                    as={Link}
                      to="/why-buy-latex-mattress"
                      className="nav-item nav-homepage dropdown_item-2"
                    >
                      Why Buy Latex Mattress
                    </NavDropdown.Item>
                    <NavDropdown.Item
                    as={Link}
                      to="/sap-to-sleep"
                      className="nav-item nav-homepage dropdown_item-3"
                    >
                      Sap To sleep
                    </NavDropdown.Item>
                    <NavDropdown.Item
                    as={Link}
                      to="/why-choose-us"
                      className="nav-item nav-homepage dropdown_item-4"
                    >
                      8 Reason's To Buy Latex
                    </NavDropdown.Item>
                  </div>
                </div>
              </NavDropdown>
              {userId && (
                <NavDropdown title='Account' className="account_details">
                  <NavDropdown.Item className="account_dropdown">
                    <Link to='/account-detail' className="account_order-details" onClick={() => setNavbarExpanded(false)}>
                      <h4 className="account_order-details_orders">ORDERS</h4>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to='/account-detail/profile-page' className="account_order-details">
                      <h4 className="account_order-details_orders" onClick={() => setNavbarExpanded(false)}>PROFILE</h4>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to='/account-detail/address-page' onClick={() => setNavbarExpanded(false)} className="account_order-details">
                      <h4 className="account_order-details_orders">MANAGE ADDRESSES</h4>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link onClick={() => setNavbarExpanded(false)}>
                      <h4 className="account_order-details_orders" onClick={handleLogout}>LOGOUT</h4>
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            </Nav>
            <div className="d-flex icon-header">
              <FontAwesomeIcon icon={faPhone} onClick={handleCall} className="nav-item icon " />
              <div className="cart-icon-container" onClick={handleAddToCartSideNav}>
                <span className="badge"> {cartItemsCount} </span>
                <FontAwesomeIcon icon={faCartShopping} className="nav-item icon " />
              </div>
              <FontAwesomeIcon icon={faUser} className="nav-item icon" onClick={handleLoginOpen} />
              <Dialog
                open={open}
                onClose={handleLoginClose}>
                <DialogContent>
                  <div className="fs-2 my-3 icon text-center">
                    <FontAwesomeIcon icon={faHand} className="hand-icon" />
                    <h4 className="fw-bold my-4 fs-4">WELCOME</h4>
                    <h6 className="mb-2 fw-bold signin">SIGN IN or SIGN UP</h6>
                  </div>
                  <hr className="text-success" />
                  <div className="form-data">
                    {!showOtpInput ? (<form onSubmit={handlePhoneSubmit}>
                      <p className="text-center otp my-4">Enter your phone number and we will send you a 6 digit OTP.</p>
                      <PhoneInput
                        international
                        defaultCountry="IN"
                        placeholder="Enter Phone Number"
                        className="w-100 phonenumber"
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        onKeyPress={handleKeyPress}
                        autoFocus
                        maxLength='15' />
                      {showError && <p className="error-message">Phone number is required.</p>} {/* Show error message */}
                      <div className="mobileotp">
                        <button className="btn btn-outline-success fw-bold">SEND OTP</button>
                      </div>
                    </form>) :
                      (<div><p className="text-center otp my-4">We have sent a 6 digit OTP to {phoneNumber}. Please enter it here. <span className="resendotp" onClick={handleResendOtp}>Resend OTP?</span></p>
                        <OtpInput phoneNumber={phoneNumber} length={6} onOtpSubmit={onOtpSubmit} handleClose={handleLoginClose} resentOtp={resentOtp} />
                      </div>)
                    }
                  </div>
                  <div class="mb-2">
                    <button class="popup-close" id="close" type="button" onClick={handleLoginClose}>
                      &#10006;
                    </button>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {sideBarshow && <Addtocart handleCloseSideNav={handleCloseSideNav} sideBarshow={sideBarshow} ></Addtocart>}
    </>
  );
}
export default Header;
