import React, { useEffect, useState } from 'react';
import './Productinfo.css';
import Stepper from 'react-stepper-horizontal';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import useUserId from './UseUserId'; // Import the custom hook
import baseURL from '../Environment';
import delivered from './../images/delivered.png';
import packag from './../images/package.png';
import rotate from './../images/rotate.png';
import shipped from './../images/shipped.png';
import thumbs from './../images/thumbs-up.png';
import { Col, Row } from 'react-bootstrap';
const ProductInformation = () => {
  // Get the orderId from the URL parameters
  const { orderId } = useParams();

  // Initialize state variables
  const [orderDetails, setOrderDetails] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [latestAddress, setLatestAddress] = useState(null);

  // Get the userId using a custom hook
  const userId = useUserId();
  // Define an array of steps for the product information
  const steps = [
    { title: <><img src={thumbs} alt="Ordered" className='productinfo_images' /><p className='productinfo_images-titles'><b>Ordered</b></p></> },
    { title: <><img src={rotate} alt="Processing" className='productinfo_images' /><p className='productinfo_images-titles'><b>Processing</b></p></> },
    { title: <><img src={packag} alt="Packed" className='productinfo_images' /><p className='productinfo_images-titles'><b>Packed</b></p></> },
    { title: <><img src={shipped} alt="Shipped" className='productinfo_images' /><p className='productinfo_images-titles'><b>Shipped</b></p></> },
    { title: <><img src={delivered} alt="Delivered" className='productinfo_images' /><p className='productinfo_images-titles'><b>Delivered</b></p></> },
  ];



  // Fetch order details for the specified user and order ID
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        if (!userId) return; // If userId is null, return early
        const response = await fetch(baseURL + `orders/getorders/${userId}/${orderId}`);
        const data = await response.json();
        setOrderDetails(data);
        // console.log("view data:", data);
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    };
    fetchOrderDetails();
  }, [userId, orderId]); // Include userId and orderId in the dependencies array

  // Scroll to the top of the window when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Fetch user addresses and set the latest address
  useEffect(() => {
    if (!userId) return; // If userId is null, return early
    fetch(baseURL + `users/${userId}/all-addresses`)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setAddresses(data.addresses);
          // Set the latest address
          if (data.addresses.length > 0) {
            setLatestAddress(data.addresses[data.addresses.length - 1]);
          }
        } else {
          console.error('Failed to fetch addresses:', data.error);
        }
      })
      .catch(error => {
        console.error('Error fetching addresses:', error);
      });
  }, [userId]); // Include userId in the dependencies array

  // Function to format a date string into a readable format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  // Check if orderDetails is null and return a loading message if true
  if (orderDetails === null) {
    return <p>Loading</p>;
  }
  //total price calculation
  const subTotal =
    orderDetails.products.map(product => product.originalPrice * product.quantity).reduce((acc, val) => acc + Number(val), 0)
  return (
    <div className='mb-5' style={{ maxHeight: '200vh' }}>
      {orderDetails ? (
        <div className='account-detials_page'>
          <div className='container orders_row'>
            <div className='order-page mb-4'>
              <h2 style={{ color: 'rgb(160 167 167)' }}> <Link to='/account-detail' style={{ color: 'rgb(156 170 170)' }}> Orders </Link>
                <FontAwesomeIcon icon={faAnglesRight} style={{ color: "rgb(156 170 170)" }} /> Order Details</h2>
            </div>
            <div className='py-2'>Order Details</div>
            <Row md={3} lg={4} className='pt-3'>
              <Col className='order_details_font'> ORDER NUMBER </Col>
              <Col className='fw-bold order_details_font'>  {orderDetails.orderId} </Col>

            </Row>
            <Row md={3} lg={4} className='pt-3'>
              <Col className='order_details_font'> ORDER PLACED </Col>
              <Col className='fw-bold order_details_font'> {formatDate(orderDetails.createdAt)} </Col>

            </Row>
            <Row md={3} lg={4} className='pt-3'>
              <Col className='order_details_font'> PAYMENT TYPE </Col>
              <Col className='fw-bold order_details_font'> {orderDetails.paymentMethod} </Col>

            </Row>
            <Row md={3} lg={4} className='pt-3'>
              <Col className='order_details_font'> PAYMENT I'D </Col>
              <Col className='fw-bold order_details_font'> {orderDetails.paymentId} </Col>
            </Row>
          </div>
          <div className='my-5 mx-auto'>
            <Stepper steps={steps} />
          </div>
          {orderDetails.products.map((product, index) => (
            <div key={product._id} className='container shoping-item' style={{ padding: '30px 40px 10px 40px', marginBottom: index < orderDetails.products.length - 1 ? '20px' : '0' }}>
              <div className='row main-2'>
                <div className='col-md-2'>
                  <div>
                    <img src={product.imageurl[0]} alt='product' className='product_img1' />
                  </div>
                </div>
                <div className='col-md-8 product-info_productinfo'>
                  <h2>{product.title}</h2>
                  <div className='product-info d-flex mt-3'>

                    {product.selectedCategoryType !== null && product.selectedCategoryType !== undefined && product.selectedCategoryType !== "" &&
                      <p className='line'>Size : <span className='text-dark'> {product.selectedCategoryType} </span> </p>
                    }
                    {product.selectedHeight !== null && product.selectedHeight !== undefined && product.selectedHeight !== "" &&
                      <p className='line'>Height: <span className='text-dark'>{product.selectedHeight}</span></p>
                    }
                    {product.selectedPackOf !== null && product.selectedPackOf !== undefined && product.selectedPackOf !== "" &&
                      <p className='line'>Pack of pillows : <span className='text-dark' > {product.selectedPackOf} </span> </p>
                    }
                    {product.selectedCover !== null && product.selectedCover !== undefined && product.selectedCover !== "" &&
                      <p className='line'>Cover : <span className='text-dark'> {product.selectedCover} </span> </p>
                    }
                    <p >Dimensions : <span className='text-dark'> {product.selectedDimension} </span> </p>
                  </div>
                  <div className='quantity my-3'>
                    <p>Qty: <span className='text-dark'> {product.quantity} </span></p>
                  </div>
                </div>
                <div className='d-flex product-ammount'>
                  <div>
                    <p className='mt-3 product-ammount_product-price'>Product Price :</p>
                  </div>
                  <div>
                    <p className='product-price mt-3'>₹{(product.quantity * product.originalPrice).toFixed(2)}/-</p>
                  </div>
                </div>

              </div>
            </div>
          ))}
          <div className='container my-4 payment-info' style={{ fontFamily: 'Montserrat' }}>
            <div className='sub-paymentinfo'>
              <h2 className='text payment-para'> Payment Information </h2>
            </div>
            <div className='d-flex payment-Information' >
              <div className='sub-total' >
                <p className='sub-ammount'> Sub Total : </p>
              </div>
              <div>
                <p className='product-price total-price'> ₹{(subTotal).toFixed(2)}/- </p>
              </div>
            </div>
            <div className='d-flex payment-Information' >
              <div className='sub-total' >
                <p className='sub-ammount'> Discount : </p>
              </div>
              <div>
                <p className='product-price total-price'>{(orderDetails.totalPrice - subTotal).toFixed(2)}/- </p>
              </div>
            </div>
            <div className='d-flex product-ammountdetails'>
              <div >
                <p className='product-ammountdetails_para'> Total Amount : </p>
              </div>
              <div >
                <p className='product-price'>₹{orderDetails.totalPrice.toFixed(0)}/- </p>
              </div>
            </div>
          </div>
          {latestAddress && (
            <div className='latest-address'>
              <div className='delivery-address mt-1'>
                <div className='delivery-item'>
                  <h2 className='text2'>Delivery Address</h2>
                </div>
                <div className='delivery-location'>
                  <p className='location'> {orderDetails.deliveryAddress.fullname}</p>
                  <p className='location'> {orderDetails.deliveryAddress.phoneNumber} , {orderDetails.deliveryAddress.email} </p>
                  <p className='location'> {orderDetails.deliveryAddress.address} , {orderDetails.deliveryAddress.city} , {orderDetails.deliveryAddress.state} - {orderDetails.deliveryAddress.pincode}  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <p>Loading</p>
      )}
    </div>
  );
}

export default ProductInformation;
