import React from 'react';
import './Header.css'; // Import CSS file for styles

const BannerShape = () => {
  return (
    <div className="banner-shape">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
      
      >
        <defs>
          <linearGradient
            id="blobSvg"
            x2="0%"
            y1="70.711%"
            y2="0%"
            x1="70.711%"
          >
            <stop
              className="stop-color1"
              offset="0%"
              stopOpacity="1"
              stopColor="green"
            ></stop>
            <stop
              className="stop-color2"
              offset="100%"
              stopOpacity="1"
              stopColor="lightgreen"
            ></stop>
          </linearGradient>
        </defs>
        <path className="animated-path" fill="url(#blobSvg)"></path>
      </svg>
    </div>
  );
};

export default BannerShape;
