import { useEffect, useRef } from "react";
import { useState } from "react";
import baseURL from "../Environment";

const OtpInput = ({ length = 6, phoneNumber, onOtpSubmit = () => { }, handleClose , resentOtp }) => {
    // State variables
    const [otp, setOtp] = useState(new Array(length).fill("")); // Stores the OTP entered by the user
    const [remainingTime, setRemainingTime] = useState(30); // Stores the remaining time for OTP validation
    const [error, setError] = useState(null); // Stores any error occurred during OTP verification
    const [timerInterval, setTimerInterval] = useState(null); // Stores the interval ID for the timer

    const inpuRefs = useRef([]); // Refs for the OTP input fields

    // useEffect hook to focus on the first input field when the component mounts
    useEffect(() => {
        if (inpuRefs.current[0]) {
            inpuRefs.current[0].focus();
        }
    }, [])

    // Function to handle the change event of the OTP input fields
    const handleChange = (index, e) => {
        const value = e.target.value;
        if (isNaN(value)) return // Check if the entered value is not a number, and return if true
        const newOtp = [...otp] // Create a copy of the current OTP array
        // Allow only one input
        newOtp[index] = value.substring(value.length - 1); // Update the OTP value at the specified index with the last character of the entered value
        setOtp(newOtp); // Update the OTP state with the new array

        // Submit trigger
        const combinedOtp = newOtp.join(""); // Combine the OTP array into a single string
        if (combinedOtp.length === length) // Check if the combined OTP length is equal to the specified length
            onOtpSubmit(combinedOtp); // Call the onOtpSubmit function with the combined OTP as the argument

        // Move to the next input if the current field is filled
        if (value && index < length - 1 && inpuRefs.current[index + 1]) {
            inpuRefs.current[index + 1].focus(); // Focus on the next input field
        }
    }

    // Function to handle the click event of the OTP input fields
    const handleClick = (index) => {
        inpuRefs.current[index].setSelectionRange(0, 1); // Set the selection range of the input field to highlight the first character
    }

    // Function to handle the keydown event of the OTP input fields
    const handleKeyDown = (index, e) => {
        if (e.key === "Backspace" &&
            !otp[index] && index > 0 &&
            inpuRefs.current[index - 1]
        ) {
            // Move focus to the previous input field on backspace
            inpuRefs.current[index - 1].focus();
        }
        if (e.key === 'Enter') {
            handleVerify(e); // Call the handleVerify function when the Enter key is pressed
        }
    }

    // Function to handle the verification of OTP
    const handleVerify = async () => {
        try {        
            const response = await fetch(baseURL + 'profile/verify-otp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ phoneNumber: phoneNumber, otp: otp.join("") })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const token = data.token; // Assuming your backend sends back a token upon successful verification

            localStorage.setItem('token', token); // Store the token in local storage

            // Navigate to current page
            // window.location.href = window.location.href;

            // After successful verification, close the login dialog
            handleClose();

            // Handle response if needed
        } catch (error) {
            console.error('There was a problem with your fetch operation:', error);
            clearInterval(timerInterval);
            setError('Incorrect OTP. Please try again.');
        }
    }
    
    // useEffect hook to reset the timer and OTP when resentOtp changes
    useEffect(() => {
        if (resentOtp) {
            setRemainingTime(30); // Reset the timer when resentOtp changes
            setError(false)
            setOtp(new Array(length).fill(""));
        }
    }, [resentOtp]);

    // useEffect hook to start the timer and handle timer updates
    useEffect(() => {
        let interval = null;
        if (remainingTime > 0) {
            interval = setInterval(() => {
                setRemainingTime((prevTime) => prevTime - 1);
            }, 1000);
            setTimerInterval(interval); // Store the interval ID in state variable
        } else {
            clearInterval(timerInterval); // Clear interval when time reaches 0
        }

        return () => clearInterval(interval); // Cleanup timer on component unmount
    }, [remainingTime, resentOtp]);

    return (
        
        <div className="text-center">
             {error && <p className="text-danger">{error}</p>} 
            {remainingTime > 0 && <p>Remaining time for OTP validation: {remainingTime} seconds</p>}
            {
                otp.map((value, index) => {
                    return <input
                        key={index}
                        type="text"
                        ref={(input) => (inpuRefs.current[index] = input)}
                        value={value}
                        onChange={(e) => handleChange(index, e)}
                        onClick={() => handleClick(index)}
                        onKeyDown={(e) => handleKeyDown(index, e)}
                        className="otpInput"
                    />
                })
            }
            <br />
           <div  className="mobileotp"><button onClick={() => { handleVerify() }} className="btn btn-outline-success fw-bold">Verify</button></div>
        </div>
    )
}

export default OtpInput
